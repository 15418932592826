import moment from 'moment-timezone';
export default {
	// async getLocationDetails (city) {
	// 	let geoData = null;
	// 	await fetch('https://maps.googleapis.com/maps/api/geocode/json?key=' + `${process.env.VUE_APP_GOOGLEMAPAPIKEY}` + '&address=' + city)
	// 		.then(function (response) {
	// 			return response.json();
	// 		}).then(function (data) {
	// 			if (data.status === 'OK') {
	// 				geoData = data;
	// 			} else {
	// 				geoData = true;
	// 			}
	// 		});
	// 	return geoData;
	// },
	async getLocationDetails (address) {
		let geoData = null;
		const url = `https://nominatim.openstreetmap.org/search?q=${address}&format=json&addressdetails=1&limit=1&timezone=1`;
		await fetch(url)
			.then(function (response) {
				return response.json();
			}).then(function (data) {
				// if (data.length > 0) {
				// 	geoData = data[0];
				// } else {
				// 	geoData = true;
				// }
				if (data.length > 0) {
					// geoData = data[0];
					// // // Extract timezone information from bounding box
					// // const lat = (parseFloat(geoData.boundingbox[0]) + parseFloat(geoData.boundingbox[1])) / 2;
					// // const lon = (parseFloat(geoData.boundingbox[2]) + parseFloat(geoData.boundingbox[3])) / 2;
					// // // const timezone = moment.tz.guess(lat + ',' + lon);
					// // const timezone = moment.tz.guess().tz(lat + ',' + lon);

					// const lat = (parseFloat(geoData.boundingbox[0]) + parseFloat(geoData.boundingbox[1])) / 2;
					// const lon = (parseFloat(geoData.boundingbox[2]) + parseFloat(geoData.boundingbox[3])) / 2;
					// const timezoneName = moment.tz.guess(lat + ',' + lon);
					// geoData.address.timezone = timezoneName;
					// const timestamp = moment().tz(timezoneName);
					// geoData.address.timezoneOffset = timestamp.utcOffset();

					// geoData.address.timezone = timezone;

					geoData = data[0];
					const lat = (parseFloat(geoData.boundingbox[0]) + parseFloat(geoData.boundingbox[1])) / 2;
					const lon = (parseFloat(geoData.boundingbox[2]) + parseFloat(geoData.boundingbox[3])) / 2;
					const timezoneName = moment.tz.guess(lat + ',' + lon);
					geoData.address.timezone = timezoneName;
					const timestamp = moment().tz(timezoneName);
					geoData.address.timezoneOffset = timestamp.utcOffset();
				} else {
					geoData = true;
				}
			});
		return geoData;
	},
	async getTimeZone (object) {
		let timeZone = null;
		await fetch('https://api.timezonedb.com/v2.1/get-time-zone?key=B1BW364LNJZR&format=json&by=position&lat=' + object.lat + '&lng=' + object.lng)
			.then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data.status === 'OK') {
					timeZone = data;
				}
			});
		return timeZone;
	},
};
