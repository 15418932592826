import Repository from '../../repositories/RepositoryFactory';
const SaRepository = Repository.get('sarepository');

export default {
	namespaced: true,
	state: {
		patientsListForMedicalInstitution: [],
		combinedHospitalsPatients: [],
	},
	mutations: {
		GET_PATIENTS_FOR_MEDICAL_INSTITUTION (state, payload) {
			state.combinedHospitalsPatients = [];

			const hospitalsList = payload.medicalInsititutionPatientsData.hospitalsList; // kot kot

			for (let i = 0; i < hospitalsList.length; i++) {
				const patientsList = hospitalsList[i].patientsList;
				for (let j = 0; j < patientsList.length; j++) {
					state.patientsListForMedicalInstitution.push(patientsList[j]);

					var combinedObject = {
						hospitalId: hospitalsList[i].hospitalId,
						hospitalName: hospitalsList[i].hospitalName,
						userId: patientsList[j].userId,
						userFullname: patientsList[j].userFullname,
						email: patientsList[j].email,
						status: patientsList[j].status,
					};

					state.combinedHospitalsPatients.push(combinedObject);
				}
			}
		},
		GET_PATIENT_DETAILS (state, payload) {
			state.combinedHospitalsPatients = [];

			state.hospitalsList = payload.medicalInsititutionPatientsData.hospitalsList;

			for (let i = 0; i < state.hospitalsList.length; i++) {
				const patientsList = state.hospitalsList[i].patientsList;
				for (let j = 0; j < patientsList.length; j++) {
					state.patientsListForMedicalInstitution.push(patientsList[j]);

					var combinedObject = {
						hospitalId: state.hospitalsList[i].hospitalId,
						hospitalName: state.hospitalsList[i].hospitalName,
						userId: patientsList[j].userId,
						userFullname: patientsList[j].userFullname,
						email: patientsList[j].email,
						status: patientsList[j].status,
					};

					state.combinedHospitalsPatients.push(combinedObject);
				}
			}
		},
	},
	actions: {
		async getPatientsForMedicalInstitution ({ commit }, object) {
			commit('GET_PATIENTS_FOR_MEDICAL_INSTITUTION', await SaRepository.getPatientsForMedicalInstitution(object));
		},
		async getPatientDetails ({ commit }, object) {
			commit('GET_PATIENT_DETAILS', await SaRepository.getPatientDetails(object));
		},
	},
	getters: {
		getPatientsForMedicalInstitution (state) {
			return state.patientsListForMedicalInstitution;
		},
		getCombinedHospitalsPatients (state) {
			return state.combinedHospitalsPatients;
		},
	},
};
