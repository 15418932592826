import Repository from '../../repositories/RepositoryFactory';

const MedicalDeviceRepository = Repository.get('medicaldevice');

export default {
	namespaced: true,
	state: {
		bloodPresure: [],
		medicalDeviceTemplates: null,
		deviceTemplates: null,
		patientActiveTreatmentPlan: null,
		medicalDevicesList: null,
		medicalDevices: [],
		patientMeasurements: [],
		devicesDefaults: [],
		combinedDevices: [],
		unassignedDevices: [],
		hubIdentifier: null,
	},
	mutations: {
		LOAD_BLOOD_PRESURE_DATA (state, payload) {
			state.bloodPresure = payload;
		},
		GET_MEDICAL_DEVICE_TEMPLATES (state, payload) {
			state.medicalDeviceTemplates = payload;
		},
		DEVICE_TEMPLATES (state, payload) {
			state.deviceTemplates = payload;
		},
		PATIENT_DEVICES (state, payload) {
			state.patientActiveTreatmentPlan = payload.data.patientActiveTreatmentPlan;
			state.medicalDevicesList = payload.data.medicalDevicesList;
			const combinedDevices = state.medicalDevicesList[0].assignedMedicalDevicesList.map(assignedDevice => {
				const matchingPlanDevice = state.patientActiveTreatmentPlan.treatmentPlanDevicesList.find(
					planDevice => planDevice.deviceType === assignedDevice.deviceType,
				);

				// Parse deviceDefaultValuesJson safely
				let deviceDefaultValuesJson = {};
				try {
					deviceDefaultValuesJson = JSON.parse(assignedDevice.deviceDefaultValuesJson) || {};
				} catch (e) {
					// console.error('Failed to parse deviceDefaultValuesJson:', e);
				}

				if (matchingPlanDevice) {
					return {
						...assignedDevice,
						deviceDefaultValuesJson,
						deviceDefaultValuesJsonNames: Object.keys(deviceDefaultValuesJson),
						monitoringDetails: {
							id: matchingPlanDevice.id,
							time1: matchingPlanDevice.time1 || '',
							time2: matchingPlanDevice.time2 || '',
							time3: matchingPlanDevice.time3 || '',
							time4: matchingPlanDevice.time4 || '',
							measurementPeriod: matchingPlanDevice.measurementPeriod,
							measurementsCount: matchingPlanDevice.measurementsCount,
							treatmentPlanId: matchingPlanDevice.treatmentPlanId,
							deviceTypeTitle: matchingPlanDevice.deviceTypeTitle,
							deviceTypeTitleEsp: matchingPlanDevice.deviceTypeTitleEsp,
						},
					};
				}
				return assignedDevice;
			});

			// Store the combined devices in the state
			state.combinedDevices = combinedDevices;
			state.hubIdentifier = payload.data.medicalDevicesList[0].hubIdentifier;
		},
		GET_DEVICES (state, payload) {
			state.medicalDevices = payload;
		},
		GET_PATIENT_MEASUREMENTS (state, payload) {
			state.patientMeasurements = payload;
		},
		GET_DEVICES_DEFAULTS (state, payload) {
			state.devicesDefaults = payload;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async createMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.createMedicalDevice(body);
			return res;
		},
		async getPossibleDevices ({ commit }) {
			// commit('GET_DEVICES', await MedicalDeviceRepository.getPossibleDevices());
			const res = await MedicalDeviceRepository.getPossibleDevices();
			commit('GET_DEVICES', res);
			return res;
		},
		// async getPossibleDevices2 ({ commit }) {
		// 	commit('GET_DEVICES', await MedicalDeviceRepository.getPossibleDevices2());
		// 	// return res;
		// },
		// eslint-disable-next-line no-unused-vars
		async editMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.editMedicalDevice(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async deleteMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.deleteMedicalDevice(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getMyMedicalDevices ({ commit }, id) {
			const res = await MedicalDeviceRepository.getMyMedicalDevices(id);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getDeviceById ({ commit }, id) {
			const res = await MedicalDeviceRepository.getDeviceById(id);
			return res;
		},
		async sendMeasurement ({ commit }, body) {
			const res = await MedicalDeviceRepository.medicaldevicesdata(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async getmedicaldevicebydeviceidentifier ({ commit }, id) {
			commit('LOAD_BLOOD_PRESURE_DATA', await MedicalDeviceRepository.getmedicaldevicebydeviceidentifier(id));
			// return res
		},
		// eslint-disable-next-line no-unused-vars
		async getMedicalDeviceTemplate ({ commit }) {
			commit('GET_MEDICAL_DEVICE_TEMPLATES', await MedicalDeviceRepository.getMedicalDeviceTemplate());
			// const res = await MedicalDeviceRepository.getMedicalDeviceTemplate()
			// return res
		},
		async getMedicalDeviceTemplateId ({ commit }, id) {
			const res = await MedicalDeviceRepository.getMedicalDeviceTemplateId(id);
			return res;
		},
		async getDeviceTemplateByManufacturer ({ commit }, object) {
			const res = await MedicalDeviceRepository.getDeviceTemplateByManufacturer(object);
			return res;
		},
		async getDeviceTemplatesByType ({ commit }, id) {
			commit('DEVICE_TEMPLATES', await MedicalDeviceRepository.getDeviceTemplatesByType(id));
		},
		async updateIsManualMeasurementForMedicalDevice ({ commit }, body) {
			commit('DEVICE_TEMPLATES', await MedicalDeviceRepository.updateIsManualMeasurementForMedicalDevice(body));
		},
		async getDeviceTemplatesByTypeAndManufacturer ({ commit }, body) {
			return await MedicalDeviceRepository.getDeviceTemplatesByTypeAndManufacturer(body);
		},
		// eslint-disable-next-line no-unused-vars
		async editMedicalDeviceTemplate ({ commit }, body) {
			const res = await MedicalDeviceRepository.editMedicalDeviceTemplate(body);
			return res;
		},
		async getmedicaldevicesforpatientV2 ({ commit }, id) {
			const res = await MedicalDeviceRepository.getmedicaldevicesforpatientV2(id);
			commit('PATIENT_DEVICES', res);
			return res;
		},
		async getmeasurementsforpatientsforhospital ({ commit }, body) {
			const res = await MedicalDeviceRepository.getmeasurementsforpatientsforhospital(body);
			commit('GET_PATIENT_MEASUREMENTS', res);
			return res;
		},
		async getdevicedefaultsforhospital ({ commit }, body) {
			const res = await MedicalDeviceRepository.getdevicedefaultsforhospital(body);
			commit('GET_DEVICES_DEFAULTS', res);
			return res;
		},
		async updateDeviceDefaultValuesJsonForMedicalDevice ({ commit }, body) {
			const res = await MedicalDeviceRepository.updateDeviceDefaultValuesJsonForMedicalDevice(body);
			return res;
		},
	},
	getters: {
		getPatientActiveTreatmentPlan (state) {
			const patientDevices = state.patientActiveTreatmentPlan.treatmentPlanDevicesList;
			return patientDevices;
		},
		getMedicalDevices (state) {
			const medicalDevices = state.medicalDevicesList[0].assignedMedicalDevicesList;
			return medicalDevices;
		},
		getMedicalDevicesList (state) {
			const medicalDevices = state.medicalDevicesList[0];
			return medicalDevices;
		},
	},
};
