import Repository from '../../repositories/RepositoryFactory';

const CountriesRepository = Repository.get('countries');

export default {
	namespaced: true,
	mutations: {
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async getCountries ({ commit }) {
			const res = await CountriesRepository.getCountries();
			return res;
		},
	},
	getters: {},
};
