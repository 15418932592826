import { CountriesError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	sortCountries (countries) {
		const US = [];
		countries.forEach(function (country) {
			if (country.countryName === 'UNITED STATES') {
				US.push(country);
			}
		});
		countries = countries.filter(country => country.countryName !== 'UNITED STATES');
		countries.sort(function (a, b) {
			if (a.countryName < b.countryName) {
				return -1;
			}
			if (a.countryName > b.countryName) {
				return 1;
			}
			return 0;
		});
		countries = US.concat(countries);
		return { data: countries };
	},
	async getCountries () {
		const response = await _axios.get('country');
		if (response.data.resFlag) {
			return this.sortCountries(response.data.data);
		}
		throw new CountriesError('No meta data!');
	},
};
