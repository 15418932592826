import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getReportsRelatedPatients (filter) {
		const response = await _axios.get('reporting/getallrelatedpatientdailyscores', { headers: { filterCase: filter } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	// async getAllReportsRelatedPatients (filter) {
	// 	const response = await _axios.get('reporting/getallrelatedpatientdailyscores', { headers: { filterCase: 'ALL' } });
	// 	if (response.data.resFlag) {
	// 		return response.data.data;
	// 	}
	// 	throw new UsersError('No meta data!');
	// },
	async getAllReportsRelatedPatients (data) {
		const response = await _axios.get('reporting/getallrelatedpatientdailyscores', { headers: { filterCase: data.filterCase, hospitalId: data.hospitalId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAllRelatedStaffAuditReport (object) {
		const response = await _axios.get('reporting/getallrelatedstaffauditreport', {
			headers: {
				hospitalid: object.hospitalId,
				organizationId: object.organizationId,
				month: object.month,
				year: object.year,
				day: object.day,
			},
		});

		if (response.status === 200) {
			return response.data.data.data;
		} else {
			throw new Error('Failed to fetch audit report: No meta data!');
		}
	},
	async getAuditReportDataLoginsAll (object) {
		const response = await _axios.get('reporting/getauditreportdataloginsall', {
			headers: {
				staffId: object.staffId,
				month: object.month,
				year: object.year,
				day: object.day,
			},
		});
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Failed to fetch audit report: No meta data!');
		}
	},
	async getauditreportdatatimetrackingsall (object) {
		const response = await _axios.get('reporting/getauditreportdatatimetrackingsall', {
			headers: {
				hospitalId: object.hospitalId,
				staffId: object.staffId,
				month: object.month,
				year: object.year,
				day: object.day,
			},
		});
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Failed to fetch audit report: No meta data!');
		}
	},
	async getauditreportdatameasurementsall (object) {
		const response = await _axios.get('reporting/getauditreportdatameasurementsall', {
			headers: {
				hospitalId: object.hospitalId,
				staffId: object.staffId,
				month: object.month,
				year: object.year,
				day: object.day,
			},
		});
		if (response.status === 200) {
			return response.data;
		} else {
			throw new Error('Failed to fetch audit report: No meta data!');
		}
	},
};
