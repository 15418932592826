import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getRoles () {
		const response = await _axios.get('role');
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
