import { PatientDiagnosisError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getDiagnosisListForPatient (patientId) {
		const headers = { headers: { patientId: patientId } };
		const response = await _axios.get('/patientdiagnosis/getdiagnosislistforpatient', headers);
		if (response.data.resFlag) {
			const patientDiagnosisJson = response.data; // .data;
			return patientDiagnosisJson;
		} else {
			throw new PatientDiagnosisError('Could not get the list!');
		}
	},
	async getPatientDiagnosisById (paramPatientDiagnosisId) {
		const response = await _axios.get(`/patientdiagnosis/${paramPatientDiagnosisId}`);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new PatientDiagnosisError('Invalid id');
		}
	},
	async createPatientDiagnosis (body) {
		const response = await _axios.post('patientdiagnosis', body);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		}
		throw new PatientDiagnosisError('No diagnosis data!');
	},
	async editPatientDiagnosis (body) {
		const response = await _axios.put('patientdiagnosis/updatediagnosisforpatient', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new PatientDiagnosisError('Could not update the data!');
	},
	async deletePatientDiagnosis (id) {
		const response = await _axios.delete(`patientdiagnosis/${id}`);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new PatientDiagnosisError('Invalid id');
		}
	},
};
