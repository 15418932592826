import _axios from '@/plugins/axios';
import { BillingsError } from '../../../lib/Errors';

export default {
    async medicalnotecategorytemplate () {
        const response = await _axios.get('medicalnotecategorytemplate');
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async medicalnotecategorytemplateforhospital (hospitalId) {
        const response = await _axios.get('medicalnotecategorytemplate/forhospital', {
            headers: {
                hospitalId: hospitalId,
            },
        });
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },

    // async getbillingauthorizationtemplatesbyid (id) {
    //     const response = await _axios.get(`billingauthorizationtemplate/${id}`);
    //     if (response.data.resFlag) {
    //         return response.data?.data;
    //     }
    //     throw new BillingsError('No summary!');
    // },
    async addmedicalnotecategorytemplate (object) {
        const response = await _axios.post('medicalnotecategorytemplate', object);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    // async billinguserauthorizationV1 () {
    //     const response = await _axios.get('billingusersauthorization');
    //     if (response.data.resFlag) {
    //         return response.data?.data;
    //     }
    //     throw new BillingsError('No summary!');
    // },
    async editmedicalnotecategorytemplate (object) {
        const { id, ...body } = object; // Extract id and create a new object without it
        try {
            const response = await _axios.put(`medicalnotecategorytemplate/${id}`, body);
            if (response.data.resFlag) {
                return response.data?.data;
            }
            throw new BillingsError('No summary!');
        } catch (error) {
            if (error.response && error.response.status === 403) {
                const errorMessage = error.response.data.msg;
                return { error: errorMessage };
            } else {
                throw error;
            }
        }
    },

    async medicalnotecategorytemplatechangestatus (object) {
        const { id, ...body } = object; // Extract id and create a new object without it
        const response = await _axios.put(`medicalnotecategorytemplate/changestatus/${id}`, body);

        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },

    async deletemedicalnotecategorytemplate (id) {
        try {
            const response = await _axios.delete(`medicalnotecategorytemplate/${id}`);
            if (response.data.resFlag) {
                return response.data;
            }
            throw new BillingsError('No summary!');
        } catch (error) {
            if (error.response && error.response.status === 403) {
                throw new Error('403 Forbidden Error');
            } else {
                throw error; // Re-throw other errors to be handled elsewhere
            }
        }
    },
    // async generateCsvHospitalPatientsForMonth (object) {
    //     const response = await _axios({
    //         url: 'billing/generatecsvhospitalpatientsformonth',
    //         method: 'GET',
    //         responseType: 'blob',
    //         headers: {
    //             hospitalId: object.hospitalId,
    //             month: object.month,
    //             year: object.year,
    //         },
    //     });
    //     if (response.status === 200) {
    //         return response;
    //     }
    //     throw new BillingsError('No summary!');
    // },
};
