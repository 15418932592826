import _axios from '@/plugins/axios';
import { BillingsError } from '../../../lib/Errors';

export default {
    async billinguserauthorization () {
        const response = await _axios.get('billingauthorizationtemplate');
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },

    async getbillingauthorizationtemplatesbyid (id) {
        const response = await _axios.get(`billingauthorizationtemplate/${id}`);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async getusersbillingsauthorizationbyid (id) {
        const response = await _axios.get(`billingusersauthorization/${id}`);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async addbillinguserauthorization (object) {
        const response = await _axios.post('billingusersauthorization', object);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async editbillinguserauthorization (object) {
        const response = await _axios.put(`billingusersauthorization/${object.id}`, object);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async billinguserauthorizationV1 () {
        const response = await _axios.get('billingusersauthorization');
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async deletebillingusersauthorization (id) {
        const response = await _axios.delete(`billingusersauthorization/${id}`);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    // async editbillingauthorizationtemplates (object) {
    //     const response = await _axios.put(`billingauthorizationtemplate/${object.id}`, object);
    //     if (response.data.resFlag) {
    //         return response.data?.data;
    //     }
    //     throw new BillingsError('No summary!');
    // },
    // async deletebillingauthorizationtemplates (id) {
    //     const response = await _axios.delete(`billingauthorizationtemplate/${id}`);
    //     if (response.data.resFlag) {
    //         return response.data?.data;
    //     }
    //     throw new BillingsError('No summary!');
    // },
    // async generateCsvHospitalPatientsForMonth (object) {
    //     const response = await _axios({
    //         url: 'billing/generatecsvhospitalpatientsformonth',
    //         method: 'GET',
    //         responseType: 'blob',
    //         headers: {
    //             hospitalId: object.hospitalId,
    //             month: object.month,
    //             year: object.year,
    //         },
    //     });
    //     if (response.status === 200) {
    //         return response;
    //     }
    //     throw new BillingsError('No summary!');
    // },
};
