import { AuthenticationError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
import Authentication from '../../models/Authentication';

export default {
	async checkAuth () {
		const response = await _axios.post('/auth/signinwithtoken');
		if (response.data.resFlag) {
			return true;
		}

		throw new AuthenticationError('Invalid username or password');
	},
	async login (data) {
		data.ip = await this.getPublicIp();
		const response = await _axios.post('/auth/signinv3', data);
		if (response.data.resFlag) {
			const userJson = new Authentication(response.data.data);
			return userJson;
		} else {
			throw new AuthenticationError('Invalid username or password');
		}
	},
	async logout () {
		await _axios.post('/auth/signout');
	},
	async signinwithtoken () {
		const response = await _axios.post('/auth/signinwithtokenv2');
		if (response.data.resFlag) {
			if (response.data.data.userObject.roleId === 5) {
				throw new AuthenticationError('You cannot authenticate as a Patient!');
			}
			const userJson = new Authentication(response.data.data);
			return userJson;
		}
		throw new AuthenticationError('Error');
	},
	async getPublicIp () {
		const url = 'https://api64.ipify.org?format=json';
		try {
			const response = await fetch(url, { method: 'GET', timeout: 3000 }); // 3 seconds timeout
			if (!response.ok) {
				// throw new Error('Network response was not ok');
				return 'IPv6: N/A IP: N/A';
			}
			const jsonResponse = await response.json();
			const ipv4 = jsonResponse.ip || 'N/A';
			const ipv6 = jsonResponse.ipv6 || 'N/A';
			return `IPv6: ${ipv6} IP: ${ipv4}`;
		} catch (error) {
			return 'IPv6: N/A IP: N/A';
		}
	},
};
