import Repository from '../../repositories/RepositoryFactory';
import Message from '../../models/Message';
import authentication from './authentication';
const NotificationsRepository = Repository.get('notifications');
export default {
	namespaced: true,
	state: {
		allNotifications: [],
		abnormalMeasurements: [],
		reminders: [],
		missMeasurements: [],
		dailyGoals: [],
		chatNotifications: [],
		notificationsWithUser: [],
		appointmentRequests: [],
		msgStatus: null,
		mentalHealth: [],
		notifyProps: null,
		loading: false,
	},
	mutations: {
		ALL_NOTIFICATIONS (state, payload) {
			state.allNotifications = payload.filter(p => { return p.NotificationDirection === 'INCOMING'; });
			state.chatNotifications = payload.filter(p => { return p.NotificationDirection === 'INCOMING' && p.NotificationTypeId === 1; }); // We should generate another 'NotificationTypeId' for Chat Messages, to differentiate them from notifications!
			state.abnormalMeasurements = payload.filter(p => { return p.NotificationTypeId === 3; });
			state.reminders = payload.filter(p => { return p.NotificationTypeId === 4; });
			state.missMeasurements = payload.filter(p => { return p.NotificationTypeId === 5; });
			state.dailyGoals = payload.filter(p => { return p.NotificationTypeId === 6; });
			state.appointmentRequests = payload.filter(p => { return p.NotificationTypeId === 7 && p.FromUserId !== authentication.state.userData.id; });
			state.mentalHealth = payload.filter(p => { return p.NotificationTypeId === 12 || p.NotificationTypeId === 13; });
		},
		CHAT_MESSAGES (state, payload) {
			state.notificationsWithUser = payload.filter(p => { return p.DeviceType === null; });
			state.notificationsWithUser.reverse();
		},
		CHAT_MESSAGES_FROM_MEDIACL_TEAM (state, payload) {
			state.notificationsWithUser = payload.filter(p => { return p.DeviceType === null; });
			state.notificationsWithUser.reverse();
		},
		ALL_NOTIFICATIONS_FROM_WS (state, payload) {
			state.allNotifications.unshift(payload);
			switch (payload.NotificationTypeId) {
			case 1:
				if (payload.NotificationTypeId === 1) {
					state.notificationsWithUser.push(payload);
					state.chatNotifications.push(payload);
				}
				break;
			case 3:
				state.abnormalMeasurements.unshift(payload);
				break;
			case 4:
				state.reminders.unshift(payload);
				break;
			case 5:
				state.missMeasurements.unshift(payload);
				break;
			case 6:
				state.dailyGoals.unshift(payload);
				break;
			case 7:
				state.appointmentRequests.unshift(payload);
				break;
			}
			if (payload.FromUserId !== undefined) {
				payload.IsRead = false;
			}
		},
		SET_LOADING (state, loading) {
			state.loading = loading;
		},
		ABNORMAL_MEASUREMENTS_FROM_WS (state, payload) {
			state.abnormalMeasurements.unshift(payload);
		},
		REMINDER_MEASUREMENTS_FROM_WS (state, payload) {
			state.reminders.unshift(payload);
		},
		MISS_MEASUREMENTS_FROM_WS (state, payload) {
			state.missMeasurements.unshift(payload);
		},
		DAILY_MEASUREMENTS_FROM_WS (state, payload) {
			state.dailyGoals.unshift(payload);
		},
		// eslint-disable-next-line no-unused-vars
		MARK_AS_READ_LAST_ONE (state, payload) {
			state.notificationsWithUser[state.notificationsWithUser.length - 1].IsRead = true;
		},
		CHANGE_MESSAGE_STATUS (state, payload) {
			payload.notificationMsg = payload.messageToSend;
			state.notificationsWithUser.push(new Message(payload));
			state.msgStatus = 'sent';
		},
		SEND_MESSAGE (state) {
			state.msgStatus = 'delivered';
		},
		PROPS_NOTIFY (state, payload) {
			state.notifyProps = payload;
		},
	},
	actions: {
		async propsNotification ({ commit }, payload) {
			commit('PROPS_NOTIFY', payload);
		},
		async sendMessage ({ commit }, body) {
			commit('SEND_MESSAGE', await NotificationsRepository.sendMessage(body));
		},
		async changeMessageStatus ({ commit }, body) {
			commit('CHANGE_MESSAGE_STATUS', body);
		},
		// eslint-disable-next-line no-unused-vars
		async sendMessageToMedicalTeam ({ commit }, body) {
			const res = await NotificationsRepository.sendMessageToMedicalTeam(body);
			return res;
		},
		async markNotificationsAsProcessed ({ commit }, body) {
			const res = await NotificationsRepository.markNotificationsAsProcessed(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async markAsRead ({ commit }, notificationId) {
			commit('MARK_AS_READ_LAST_ONE', await NotificationsRepository.markAsRead(notificationId));
		},
		// eslint-disable-next-line no-unused-vars
		// async markNotificationAsRead ({ commit }, notificationId) {
		// 	await NotificationsRepository.markAsRead(notificationId);
		// },
		// async markNotificationsAsProcessed ({ commit }, notificationId, resolutionComments) {
		// 	await NotificationsRepository.markNotificationsAsProcessed(notificationId, resolutionComments);
		// },
		// eslint-disable-next-line no-unused-vars
		async markAllAsReadWithUser ({ commit }, id) {
			await NotificationsRepository.markAllAsReadWithUser(id)
			;
		},
		async ALL_NOTIFICATIONS ({ commit }, filter) {
			commit('SET_LOADING', true);
			try {
				const notifications = await NotificationsRepository.ALL_NOTIFICATIONS(filter);
				commit('ALL_NOTIFICATIONS', notifications);
			} catch (error) {
				// console.error('Error fetching notifications:', error);
			} finally {
				commit('SET_LOADING', false);
			}
		},
		async getNotificationsWithPatient ({ commit }, object) {
			commit('ALL_NOTIFICATIONS', await NotificationsRepository.getNotificationsWithPatient(object));
		},
		async getMessagesWithUser ({ commit }, object) {
			commit('CHAT_MESSAGES', await NotificationsRepository.getMessagesWithUser(object));
		},
		async getMessagesWithUserFromMedicalTeam ({ commit }, object) {
			commit('CHAT_MESSAGES_FROM_MEDIACL_TEAM', await NotificationsRepository.getMessagesWithUserFromMedicalTeam(object));
		},
		async initVideoCall ({ commit }, object) {
			const res = await NotificationsRepository.initVideoCall(object);
			return res;
		},
	},
	getters: {
		loading (state) {
			return state.loading;
		},
	},
};
