import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async createMedicalTeam (body) {
		const response = await _axios.post('medicalteam', body);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async assignMemberToMedicalTeam (body) {
		const response = await _axios.post('medicalteam/assignmemberusertomedicalteam', body);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async deassignMemberToMedicalTeam (body) {
		const response = await _axios.post('medicalteam/deassignmemberuserfrommedicalteam', body);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async assignMultipleMembersToMedicalTeam (body) {
		// {
		// 	"MedicalTeamId":687,
		// 	"MemberUserIdList":[1056,1058]
		// }
		const response = await _axios.post('medicalteam/assignmultiplememberusertomedicalteam', body);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async deassignMultipleMembersFromMedicalTeam (body) {
		// {
		// 	"MedicalTeamId":687,
		// 	"MemberUserIdList":[1056,1058]
		// }
		const response = await _axios.post('medicalteam/deassignmultiplememberuserfrommedicalteam', body);
		if (response.data.resFlag) {
			const userJson = response.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async editMedicalTeam (body) {
		const response = await _axios.put('medicalteam/updatemedicalteamforpatient', body);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async deleteMedicalTeam (body) {
		const response = await _axios.delete('medicalteam/deletemedicalteamforpatient', { headers: { patientId: body.patientId, hospitalId: body.hospitalId } });
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new UsersError('Invalid id');
		}
	},
	async getMedicalTeamForPatient (patientId) {
		const response = await _axios.get('medicalteam/getmedicalteamsforpatient', { headers: { patientId: patientId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMedicalTeamForDoctor (doctorId, hospitalId) {
		// const response = await _axios.get('medicalteam/getmedicalteamsfordoctor', { headers: { doctorId: doctorId, hospitalId: hospitalId } });
		const response = await _axios.get('medicalteam/getmedicalteamsfordoctorv2', { headers: { doctorId: doctorId, hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMedicalTeamForNurse (nurseId, hospitalId) {
		// const response = await _axios.get('medicalteam/getmedicalteamsfornurse', { headers: { nurseId: nurseId, hospitalId: hospitalId } });
		const response = await _axios.get('medicalteam/getmedicalteamsfornursev2', { headers: { nurseId: nurseId, hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMedicalTeamForHospitalAdmin (adminId) {
		// const response = await _axios.get('medicalteam/getmedicalteamsforhospitaladmin', { headers: { hospitalAdminId: adminId } });
		const response = await _axios.get('medicalteam/getmedicalteamsforhospitaladminv2', { headers: { hospitalAdminId: adminId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getMedicalTeams (hospitalId) {
		// const response = await _axios.get('medicalteam/getmedicalteamsforhospitaladmin', { headers: { hospitalAdminId: adminId } });
		const response = await _axios.get('medicalteam/getmedicalteamsforhospital', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
};
