import _axios from '@/plugins/axios';
import { TimeTrackingError } from '../../../lib/Errors';

export default {
	// /api/timetracking/getnotfinishedwithpatient   GET
	// {header: PatientId, SessionGuid}
	async getNotFinishedWithPatient (object) {
		const response = await _axios.get('timetracking/getnotfinishedwithpatient', { headers: object });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new TimeTrackingError('Error geting time tracking info');
	},

	// /api/timetracking/starttrackingwithpatient   POST
	// {header: SessionGuid}
	// body:{
	// "patientId": 833,
	// "visitedPageUrl": "PatientDetailsWEB"
	// }
	async starttrackingwithpatient (body) {
		const response = await _axios.post('timetracking/starttrackingwithpatient', body);
		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new TimeTrackingError('Start tracking error');
		}
	},

	// /api/timetracking/stoptrackingwithpatient PUT
	// {header: SessionGuid}
	// {
	// "trackingId": 20
	async stoptrackingwithpatient (body) {
		const response = await _axios.put('timetracking/stoptrackingwithpatient', body);
		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new TimeTrackingError('Stop tracking error');
		}
	},
};
