import Repository from '../../repositories/RepositoryFactory';
const AdminRepository = Repository.get('admin');

export default {
    namespaced: true,
    state: {
     medicalInstitutions: null,
     billingReport: null,
    },
    mutations: {
        GET_MEDICAL_INSTITUTIONS (state, payload) {
            state.medicalInstitutions = payload;
        },
        GET_BILLING_REPORT (state, payload) {
            state.billingReport = payload;
        },
    },
    actions: {
        async getMedicalInstitutions ({ commit }, body) {
            commit('GET_MEDICAL_INSTITUTIONS', await AdminRepository.getMedicalInstitutions(body));
        },
        async getBillingReport ({ commit }, body) {
            commit('GET_BILLING_REPORT', await AdminRepository.getBillingReport(body));
        },
    },
};
