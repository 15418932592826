import Repository from '../../repositories/RepositoryFactory';

const AppointmentRequestsRepository = Repository.get('appointmentrequests');

export default {
	namespaced: true,
	state: {
		appointments: null,
	},
	mutations: {
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async approveRequest ({ commit }, body) {
			await AppointmentRequestsRepository.approveRequest(body);
		},
	},
	getters: {},
};
