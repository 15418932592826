import Repository from '../../repositories/RepositoryFactory';
const BillingsRepository = Repository.get('billings');

export default {
	namespaced: true,
	state: {
		billingSummaryForMonth: [],
		getCsvDataPreview: null,
		billingDetailsForPatientForMonth: null,
		billingDetailsForAllHospitalForMonth: null,
		generatedCsvFile: null,
		billingsLinksList: null,
		// billingDataForHospitalAdmin: null,
		// billingDataForOrganizationalAdmin: null,
		// billingCsv: null,
	},
	mutations: {
		GET_SUMMARY_FOR_PATIENTS_MONTH (state, payload) {
			state.billingSummaryForMonth = payload;
		},
		GET_SUMMARY_FOR_PATIENT_MONTH (state, payload) {
			state.billingDetailsForPatientForMonth = payload;
		},
		GET_DETAILS_FOR_PATIENT_MONTH (state, payload) {
			state.billingDetailsForPatientForMonth = payload;
		},
		GET_BILLINGS_FOR_ALL_HOSPITAL_MONTH (state, payload) {
			state.billingDetailsForAllHospitalForMonth = payload;
		},
		GET_BILLINGS_FOR_PATIENTS_MONTH (state, payload) {
			state.billingDetailsForAllPatientsForMonth = payload;
		},
		GET_BILLINGS_FOR_ALL_ORGANIZATION_MONTH (state, payload) {
			state.billingDetailsForAllHospitalForMonth = payload;
		},
		GENERATE_CSV_FOR_ORGANIZATION_MONTH (state, payload) {
			state.generatedCsvFile = payload;
		},
		GENERATE_CSV_FOR_HOSPITAL_MONTH (state, payload) {
			state.generatedCsvFile = payload;
		},
		GET_BILLING_LINKS (state, payload) {
			state.billingsLinksList = payload;
		},
		GET_CSV_DATA_FOR_MONTH (state, payload) {
			state.getCsvDataPreview = payload.data;
		},
		// SEND_BILLING_EMAIL(state,payload){
		// 	state.
		// }
	},
	actions: {
		async getbillingsummaryforallpatientsformonth ({ commit }, object) {
			commit('GET_SUMMARY_FOR_PATIENTS_MONTH', await BillingsRepository.getbillingsummaryforallpatientsformonth(object));
		},
		async getbillingsummaryforpatientformonth ({ commit }, object) {
			commit('GET_SUMMARY_FOR_PATIENT_MONTH', await BillingsRepository.getbillingsummaryforpatientformonth(object));
		},
		async getbillingdetailsforpatientformonth ({ commit }, object) {
			commit('GET_DETAILS_FOR_PATIENT_MONTH', await BillingsRepository.getbillingdetailsforpatientformonth(object));
		},
		async generateforallhospitalpatientsformonth ({ commit }, object) {
			commit('GET_BILLINGS_FOR_ALL_HOSPITAL_MONTH', await BillingsRepository.generateforallhospitalpatientsformonth(object));
		},
		async generateforpatientformonth ({ commit }, object) {
			commit('GET_BILLINGS_FOR_PATIENTS_MONTH', await BillingsRepository.generateforpatientformonth(object));
		},
		async generateforallorganizationpatientsformonth ({ commit }, object) {
			commit('GET_BILLINGS_FOR_ALL_ORGANIZATION_MONTH', await BillingsRepository.generateforallorganizationpatientsformonth(object));
		},
		async generateCsvOrganizationPatientsForMonth ({ commit }, object) {
			commit('GENERATE_CSV_FOR_ORGANIZATION_MONTH', await BillingsRepository.generateCsvOrganizationPatientsForMonth(object));
		},
		async generateCsvHospitalPatientsForMonth ({ commit }, object) {
			commit('GENERATE_CSV_FOR_HOSPITAL_MONTH', await BillingsRepository.generateCsvHospitalPatientsForMonth(object));
		},
		async getBillingLinksForOrganization ({ commit }, object) {
			commit('GET_BILLING_LINKS', await BillingsRepository.getBillingLinksForOrganization(object));
		},
		async getCsvDataForMonth ({ commit }, object) {
			commit('GET_CSV_DATA_FOR_MONTH', await BillingsRepository.getCsvDataForMonth(object));
		},
		async sendBillingLink ({ commit }, billingCodeLinkId) {
			try {
				await BillingsRepository.sendBillingLink(billingCodeLinkId);
			} catch (error) {
				// console.error('Error sending billing link:', error);
			}
		},
	},
	getters: {},
};
