import Repository from '../../repositories/RepositoryFactory';
import UserBillingsAuthorizationRepository from '@/data/repositories/v1/UserBillingsAuthorizationRepository';
Repository.get('userBillingsAuthorization');
export default {
    namespaced: true,
    state: {
        billingsTemplates: [],
        getBillingsTemplateById: null,
        getUserBillingsTemplateById: null,
        billingTemplatesAll: [],
    },
    mutations: {
        GET_BILLINGS_TEMPLATES (state, payload) {
            state.billingsTemplates = payload;
        },
        GET_BILLINGS_TEMPLATES_BY_ID (state, payload) {
            state.getBillingsTemplateById = payload;
        },
        GET_USER_BILLINGS_BY_ID (state, payload) {
            state.getUserBillingsTemplateById = payload;
        },
        GET_BILLINGS_TEMPLATES_ALL (state, payload) {
            state.billingTemplatesAll = payload;
        },
    },
    actions: {
        async billinguserauthorization ({ commit }, object) {
            commit('GET_BILLINGS_TEMPLATES', await UserBillingsAuthorizationRepository.billinguserauthorization(object));
        },
        async getusersbillingsauthorizationbyid ({ commit }, object) {
            commit('GET_USER_BILLINGS_BY_ID', await UserBillingsAuthorizationRepository.getusersbillingsauthorizationbyid(object));
        },
        async getbillingauthorizationtemplatesbyid ({ commit }, object) {
            commit('GET_BILLINGS_TEMPLATES_BY_ID', await UserBillingsAuthorizationRepository.getbillingauthorizationtemplatesbyid(object));
        },
        async addbillinguserauthorization ({ commit }, object) {
            return UserBillingsAuthorizationRepository.addbillinguserauthorization(object);
        },
        async editbillinguserauthorization ({ commit }, object) {
            return UserBillingsAuthorizationRepository.editbillinguserauthorization(object);
        },
        async deletebillingusersauthorization ({ commit }, object) {
            return UserBillingsAuthorizationRepository.deletebillingusersauthorization(object);
        },
        async billinguserauthorizationV1 ({ commit }, object) {
            commit('GET_BILLINGS_TEMPLATES_ALL', await UserBillingsAuthorizationRepository.billinguserauthorizationV1(object));
        },
        // async deletebillingauthorizationtemplates ({ commit }, object) {
        //     return UserBillingsAuthorizationRepository.deletebillingauthorizationtemplates(object);
        // },

    },
    getters: {},
};
