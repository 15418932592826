import { VerifiersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';

export default {
	async dinstinctDomains (header) {
		const response = await _axios.get('verifier/dinstinctDomains', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiers (header) {
		const response = await _axios.get('verifier/getallverifiers', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getAllVerifiers (header) {
		const response = await _axios.get('verifier/getallverifiers', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForDomain (header) {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForDomainMentalHealth () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'MENTAL_HEALTH_CATEGORY' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForDomainSpecialization () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'SPECIALIZATION_TYPE' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForDomainMedicalNoteTypeCategory () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'MEDICAL_NOTE_CATEGORY_TYPE' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getICD10CodeForDomainSpecialization () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'ICD_10_MAIN_CODES' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getHealthInsurance () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'HEALTH_INSURANCE_PROGRAM' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForDomainSurveyType () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'SURVEY_ANSWER_TYPE' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getDataFilterTypes () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'FILTER_TYPES' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getVerifiersForMultipleDomains (header) {
		const response = await _axios.get('verifier/getverifiersformultipledomains', { headers: header });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getSurveyRecurrencePeriod () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'SURVEY_RECURRENCER_RULE' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
	async getBillingMonitoringTypes () {
		const response = await _axios.get('verifier/getverifiersfordomain', { headers: { domainValue: 'BILLING_MONITORING_TYPE' } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},

	async getVerifierById (id) {
		const response = await _axios.get(`verifier/${id}`);
		if (response.data.resFlag) {
			const userJson = response.data.data;
			return userJson;
		} else {
			throw new VerifiersError('Invalid id');
		}
	},
	async createVerifire (body) {
		const res = await _axios.post('verifier', body);
		return res;
	},
	async editVerifire (body) {
		return await _axios.put(`verifier/${body.id}`, body);
	},
	async deleteVerifierById (id) {
		return await _axios.delete(`verifier/${id}`);
		// const response = await _axios.delete(`verifier/${id}`);
		// if (response.data.resFlag) {
		// 	const userJson = response.data.data;
		// 	return userJson;
		// } else {
		// 	throw new VerifiersError('Invalid id');
		// }
	},
	async getTimeZones () {
		const response = await _axios.get('verifier/gettimezones');
		if (response.status === 200) {
			return response.data.data;
		}
		throw new VerifiersError('No meta data!');
	},
};
