import _axios from '@/plugins/axios';
import { BillingsError } from '../../../lib/Errors';

export default {
    async billingauthorizationtemplates () {
        const response = await _axios.get('billingauthorizationtemplate');
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async getbillingauthorizationtemplatesbyid (id) {
        const response = await _axios.get(`billingauthorizationtemplate/${id}`);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async addbillingauthorizationtemplates (object) {
        const response = await _axios.post('billingauthorizationtemplate', object);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async editbillingauthorizationtemplates (object) {
        const response = await _axios.put(`billingauthorizationtemplate/${object.id}`, object);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async deletebillingauthorizationtemplates (id) {
        const response = await _axios.delete(`billingauthorizationtemplate/${id}`);
        if (response.data.resFlag) {
            return response.data?.data;
        }
        throw new BillingsError('No summary!');
    },
    async generateCsvHospitalPatientsForMonth (object) {
        const response = await _axios({
            url: 'billing/generatecsvhospitalpatientsformonth',
            method: 'GET',
            responseType: 'blob',
            headers: {
                hospitalId: object.hospitalId,
                month: object.month,
                year: object.year,
            },
        });
        if (response.status === 200) {
            return response;
        }
        throw new BillingsError('No summary!');
    },
};
