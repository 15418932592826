'use strict';
import axios from 'axios';

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const config = {
	baseURL: process.env.VUE_APP_API_URL,
	timeout: 60 * 1000, // Timeout
	withCredentials: false, // Check cross-site Access-Control
	// Accept: '*/*',
};

const api = axios.create(config);
export function setAuthToken (token) {
	api.defaults.headers.SessionGuid = token ? `${token}` : null;
}
export default api;
