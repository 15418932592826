import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async createTreatmentPlan (body) {
		const response = await _axios.post('treatmentplan', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getTreatmentPlansForPatient (id) {
		const response = await _axios.get('treatmentplan/gettreatmentplansforpatient', { headers: { patientId: id } });
		if (response.status === 200) {
			if (response.data.resFlag) {
				return response.data.data;
			} else return [];
		}
		throw new UsersError('No meta data!');
	},
	async getActiveTreatmentPlan (id) {
		const response = await _axios.get('treatmentplan/getactivetreatmentplanforpatient', { headers: { patientId: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async assignDevice (body) {
		const response = await _axios.post('treatmentplan/assigndevice', body);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async deassignDevice (id) {
		const response = await _axios.delete('treatmentplan/deassigndevice', { headers: { treatmentPlanDeviceId: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateDeviceTimes (object) {
		const response = await _axios.put('treatmentplan/updatetreatmanplandevice', object);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateTreatmentPlan (body) {
		const response = await _axios.put('treatmentplan/updatetitledescription', body);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateTreatmentPlanDevice (body) {
		const response = await _axios.put('treatmentplan/updatetreatmanplandevice', body);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async duplicateActiveMonitoringPlan (id) {
		const response = await _axios.get('treatmentplan/duplicatetreatmentplan', { headers: { treatmentPlanId: id } });
		if (response.data.resFlag) {
			return response.data.msg;
		}
		throw new UsersError('No meta data!');
	},

	// the functions below are related to client monitoring plans
	async relatedClientMonitoringPlans () {
		const response = await _axios.get('treatmentplan/relatedclientmonitoringplans');
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getClientMonitoringPlanById (id) {
		const response = await _axios.get(`treatmentplan/treatmentplan/${id}`);
		if (response.status === 200) {
			if (response.data.resFlag) {
				return response.data.data;
			} else return null;
		}
		throw new UsersError('No meta data!');
	},
	async createClientMonitoringPlan (body) {
		const response = await _axios.post('treatmentplan/createclientmonitoringplan', body);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateClientMonitoringPlan (body) {
		const response = await _axios.put(`treatmentplan/${body.id}`, body);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateTitleDescription (body) {
		const response = await _axios.put('treatmentplan/updatetitledescription', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteMonitoringPlan (body) {
		const response = await _axios.delete(`treatmentplan/${body.id}`, body);
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async deassignpatientfromclientmonitoringplan (body) {
			const response = await _axios.delete('treatmentplan/deassignpatientfromclientmonitoringplan', {
				data: { treatmentPlanId: body.treatmentPlanId, patientUserId: body.patientUserId },
			});
			if (response.data.resFlag) {
				return response.data;
			}
	},
	async assignpatienttodefaultclientmonitoringplan (body) {
		const response = await _axios.post('treatmentplan/assignpatienttodefaultclientmonitoringplan', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
