import Repository from '../../repositories/RepositoryFactory';

const TrustedHubRepository = Repository.get('trustedhub');

export default {
	namespaced: true,
	state: {
	},
	mutations: {
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		async createTrustedHub ({ commit }, body) {
			const res = await TrustedHubRepository.createTrustedHub(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async deleteTrustedHub ({ commit }, body) {
			const res = await TrustedHubRepository.deleteTrustedHub(body);
			return res;
		},
		// eslint-disable-next-line no-unused-vars
		async editTrustedHub ({ commit }, body) {
			const res = await TrustedHubRepository.editTrustedHub(body);
			return res;
		},
	},
	getters: {},
};
