import Vue from 'vue';
import VueRouter from 'vue-router';
import { requireAuth } from '@/router/authentication.js';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		meta: { requireAuth: true },
		component: () => import('@/views/Index'),
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: () => import('@/views/Client/partials/Dashboard.vue'),
			},
			{
				path: '/monitoring',
				name: 'Monitoring',
				component: () => import('@/views/Client/components/MonitoringComponent'),
			},
			{
				path: '/patientdetails/:id(\\d+)',
				name: 'Patient Details',
				component: () => import('@/views/Client/components/PatientDetailsTest'),
			},
			{
				path: '/devicemeasurements/:id(\\d+)', // Expr. matches only numbers!',
				name: 'Device Measurements',
				component: () => import('@/views/Client/partials/DeviceMeasurements'),
			},
			{
				path: '/organizations',
				name: 'Organization',
				component: () => import('@/views/Admin/Organizations.vue'),
			},
			{
				path: '/admins',
				name: 'Admins',
				component: () => import('@/views/Admin/Admins'),
			},
			{
				path: '/offices',
				name: 'Office',
				component: () => import('@/views/Admin/Offices.vue'),
			},
			{
				path: '/patients',
				name: 'Patients',
				component: () => import('@/views/Client/partials/Patients'),
			},
			{
				path: '/billings',
				name: 'Billings',
				component: () => import('@/views/Client/partials/Billings'),
			},
			{
				path: '/medicalteam',
				name: 'Medical Team',
				component: () => import('@/views/Client/partials/MedicalTeam'),
			},
			{
				path: '/medicalstaff',
				name: 'Medical Staff',
				component: () => import('@/views/Client/partials/MedicalStaff'),
			},
			{
				path: '/support',
				name: 'Support',
				component: () => import('@/views/Client/partials/Support'),
			},
			{
				path: '/Verifier',
				name: 'Verifier',
				component: () => import('@/views/Admin/Verifiers.vue'),
			},
			{
				path: '/SaReports',
				name: 'SA_Reports',
				component: () => import('@/views/Admin/SaReports'),
			},
			{
				path: '/OaReports',
				name: 'OA_Reports',
				component: () => import('@/views/Admin/OaReports'),
			},
			// {
			// 	path: '/mentalhealthtemplate',
			// 	name: 'Wellness Template',
			// 	component: () => import('@/views/Client/partials/Mental.vue'),
			// },
			// {
			// 	path: '/mentalhealthpatient',
			// 	name: 'Wellness Patient',
			// 	component: () => import('@/views/Client/partials/MentalHealthPatients'),
			// },
			{
				path: '/wellnessassessments',
				name: 'Assessments',
				component: () => import('@/views/Client/partials/Assessments.vue'),
			},
			{
				path: '/invoices',
				name: 'Invoices',
				component: () => import('@/views/Client/partials/Invoices'),
			},
			{
				path: '/dailyreports',
				name: 'Daily Reports',
				component: () => import('@/views/Client/components/DailyReports.vue'),
			},
			{
				path: '/monthlyreview',
				name: 'Monthly Review',
				component: () => import('@/views/Client/components/MonthlyReview.vue'),
			},
			{
				path: '/billingsettings',
				name: 'Billing Settings',
				component: () => import('@/views/Client/partials/BillingsSettingsTemplate.vue'),
			},
			{
				path: '/UserBillingSettings',
				name: 'User Billing Settings',
				component: () => import('@/views/Client/partials/UserBillingsSettings.vue'),
			},
			{
				path: '/medicalNotesCategory',
				name: 'Medical Note Category',
				component: () => import('@/views/Client/partials/MedicalNotesCategory.vue'),
			},
			{
				path: '/emptyComponent',
				name: 'Empty Component',
				component: () => import('@/views/Client/partials/EmptyComponent.vue'),
			},
			{
				path: '/dashboardFacility',
				name: 'Facility Dashboard',
				component: () => import('@/views/Client/partials/DashboardFacility.vue'),
			},
			{
				path: '/dashboardAdmin',
				name: 'Admin Dashboard',
				component: () => import('@/views/Client/partials/DashboardOrgAdmin.vue'),
			},
			{
				path: '/dashboardsystemadmin',
				name: 'System Admin Dashboard',
				component: () => import('@/views/Client/partials/DashboardSystemAdmin.vue'),
			},
			{
				path: '/partnerBillingReports',
				name: 'Partner Billing Reports',
				component: () => import('@/views/Client/partials/PartnerBillingReports.vue'),
			},
			{
				path: '/residentMapping',
				name: 'Mapping Patients',
				component: () => import('@/views/Client/partials/ResidentMapping.vue'),
			},
			{
				path: '/medicalStaffMapping',
				name: 'Mapping Medical Staff',
				component: () => import('@/views/Client/partials/MedicalStaffMapping.vue'),
			},
			{
				path: '/logs',
				name: 'Logs',
				component: () => import('@/views/Client/components/Logs.vue'),
			},
		],
	},
	{
		path: '/auth',
		redirect: '/auth/login',
		name: 'Login',
		component: () => import('@/views/authentication/Login'),
		children: [
			{
				path: '/auth/login',
				component: () => import('@/views/authentication/Login'),
			},
		],
	},
	{
		path: '/api/user/validatetoken',
		name: 'Confirm Validation',
		component: () => import('@/views/authentication/ResetPassword'),
	},
	{
		path: '/auth/successfully',
		name: 'SuccessfullyChangedPassword',
		component: () => import('@/views/authentication/SuccessfullyChangedPassword'),
	},
	{
		path: '/videoRoom/:id(\\d+)/:userId(\\d+)/:appointmentId(\\d+)',
		meta: { reqviewsreAuth: true },
		name: 'VideoRoom',
		component: () => import('@/views/Client/partials/VideoRoom'),
	},
	{
		path: '/accountdeletionrequest',
		name: 'Deletion Request',
		component: () => import('@/views/authentication/DeleteAccountForm'),
	},
	{
		path: '/profileremovalinfo',
		name: 'Profile Removal Info',
		component: () => import('@/views/authentication/ProfileRemovalInfo'),
	},
	{
		path: '/auth/forgetpassword',
		name: 'Forget Password',
		component: () => import('@/views/authentication/ForgetPassword'),
	},
	{
		path: '/measurements',
		name: 'HTML Rendering',
		component: () => import('@/views/Client/partials/no-auth/RenderData'),
	},
	{
		path: '/measurements/:hubIdentifier',
		name: 'Patient Report',
		component: () => import('@/views/Client/partials/no-auth/RenderDataForPatient'),
	},
	{
		path: '/:pathMatch(.*)*', redirect: '/auth/login',
	},
];
const router = new VueRouter({
	mode: 'history',
	// base: process.env.BASE_URL,
	routes: routes,
});

router.beforeEach(requireAuth);
export default router;
