import Repository from '../../repositories/RepositoryFactory';

const TimeTrackingRepository = Repository.get('timetracking');

export default {
	namespaced: true,
	state: {
		startTimeTrackingInfo: null,
	},
	mutations: {
		GET_NOT_FINISHED_WITH_PATIENT (state, payload) {
			state.startTimeTrackingInfo = payload;
		},
	},
	actions: {
		// async getNotFinishedWithPatient ({ commit }, body) {
		// 	// Return the promise here
		// 	return TimeTrackingRepository.getNotFinishedWithPatient(body)
		// 		.then((result) => {
		// 			commit('GET_NOT_FINISHED_WITH_PATIENT', result);
		// 			return result; // You can return the result if needed
		// 		})
		// 		.catch((error) => {
		// 			// Handle errors if necessary
		// 			throw error; // Rethrow the error if needed
		// 		});
		// },
		async getNotFinishedWithPatient ({ commit }, body) {
			commit('GET_NOT_FINISHED_WITH_PATIENT', await TimeTrackingRepository.getNotFinishedWithPatient(body));
		},
		async starttrackingwithpatient ({ commit }, object) {
			commit('GET_NOT_FINISHED_WITH_PATIENT', await TimeTrackingRepository.starttrackingwithpatient(object));
		},
		async stoptrackingwithpatient ({ commit }, object) {
			commit('GET_NOT_FINISHED_WITH_PATIENT', await TimeTrackingRepository.stoptrackingwithpatient(object));
		},

		// // /api/timetracking/getnotfinishedwithpatient   GET
		// // {header: PatientId, SessionGuid}
		// async getnotfinishedwithpatient (object) {
		// 	const response = await _axios.get('timetracking/getnotfinishedwithpatient', { headers: object });
		// 	if (response.data.resFlag) {
		// 		return response.data;
		// 	}
		// 	throw new TimeTrackingError('Error geting time tracking info');
		// },

		// // /api/timetracking/starttrackingwithpatient   POST
		// // {header: SessionGuid}
		// // body:{
		// // "patientId": 833,
		// // "visitedPageUrl": "PatientDetailsWEB"
		// // }
		// async starttrackingwithpatient (body) {
		// 	const response = await _axios.post('timetracking/starttrackingwithpatient', body);
		// 	if (response.data.resFlag) {
		// 		return response.data;
		// 	} else {
		// 		throw new TimeTrackingError('Start tracking error');
		// 	}
		// },

		// // /api/timetracking/stoptrackingwithpatient PUT
		// // {header: SessionGuid}
		// // {
		// // "trackingId": 20
		// async stoptrackingwithpatient (body) {
		// 	const response = await _axios.put('timetracking/stoptrackingwithpatient', body);
		// 	if (response.data.resFlag) {
		// 		return response.data;
		// 	} else {
		// 		throw new TimeTrackingError('Stop tracking error');
		// 	}
		// },

	},
	getters: {},
};
