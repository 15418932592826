/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory';

const InvoicesRepository = Repository.get('invoices');

export default {
    namespaced: true,
    state: {
        allInvoices: null,
        allInvoicesForOrganization: null,
        invoiceById: null,
        invoiceReferencesForOrganization: null,
        generatedPdfFile: null,
    },
    mutations: {
        GET_INVOICES (state, payload) {
            state.allInvoices = payload.data;
        },
        GET_INVOICE_BY_ID (state, payload) {
            state.invoiceById = payload;
        },
        GET_RELATED_INVOICES_FOR_ORGANIZATION (state, payload) {
            state.allInvoicesForOrganization = payload.data.usersRelatedToHospital;
        },
        GET_INVOICE_REFERENCES_FOR_ORGANIZATION (state, payload) {
            state.invoiceReferencesForOrganization = payload.data;
        },
        GET_PDF_FOR_INVOICE_ID (state, payload) {
            state.generatedPdfFile = payload;
        },
    },
    actions: {
        // async getHospitals ({ commit }) {
        // 	commit('GET_HOSPITALS', await HospitalsRepository.getHospitals());
        // },
        // async getHospitalById ({ commit }, id) {
        // 	commit('GET_HOSPITAL_BY_ID', await HospitalsRepository.getHospitalById(id));
        // },
        // async getHospitalAdmins ({ commit }, id) {
        // 	commit('GET_HOSPITAL_ADMINS', await HospitalsRepository.getHospitalAdmins());
        // },
        // // eslint-disable-next-line no-unused-vars
        // async createHospital ({ commit }, body) {
        // 	const res = await HospitalsRepository.createHospital(body);
        // 	return res;
        // },
        // async editHospital ({ commit }, body) {
        // 	const res = await HospitalsRepository.editHospital(body);
        // 	return res;
        // },
        // async deleteHospital ({ commit }, id) {
        // 	const res = await HospitalsRepository.deleteHospital(id);
        // 	return res;
        // },
        async getInvoiceReferencesForOrganization ({ commit }, organizationId) {
            const response = await InvoicesRepository.getInvoiceReferencesForOrganization(organizationId);
            // commit('GET_INVOICE_REFERENCES_FOR_ORGANIZATION', await InvoicesRepository.getInvoiceReferencesForOrganization(organizationId));
            commit('GET_INVOICE_REFERENCES_FOR_ORGANIZATION', response);
            return response;
        },
        async getInvoices ({ commit }) {
            commit('GET_INVOICES', await InvoicesRepository.getInvoices());
        },
        async generateInvoiceForOrganization ({ commit }, body) {
            const res = await InvoicesRepository.generateInvoiceForOrganization(body);
            return res;
        },
        async updateInvoiceForOrganization ({ commit }, body) {
            const res = await InvoicesRepository.updateInvoiceForOrganization(body);
            return res;
        },
        async getInvoicePdf ({ commit }, object) {
            commit('GET_PDF_FOR_INVOICE_ID', await InvoicesRepository.getInvoicePdf(object));
        },
        async deleteInvoiceById ({ commit }, id) {
            const res = await InvoicesRepository.deleteInvoice(id);
            return res;
        },
    },
    getters: {},
};
