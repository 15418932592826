import Repository from '../../repositories/RepositoryFactory';
import BillingAuthorizationRepository from '@/data/repositories/v1/BillingAuthorizationRepository';
Repository.get('billings');
export default {
    namespaced: true,
    state: {
        billingsTemplates: [],
        getBillingsTemplateById: null,
    },
    mutations: {
        GET_BILLINGS_TEMPLATES (state, payload) {
            state.billingsTemplates = payload;
        },
        GET_BILLINGS_TEMPLATES_BY_ID (state, payload) {
            state.getBillingsTemplateById = payload;
        },
    },
    actions: {
        async billingauthorizationtemplates ({ commit }, object) {
            commit('GET_BILLINGS_TEMPLATES', await BillingAuthorizationRepository.billingauthorizationtemplates(object));
        },
        async getbillingauthorizationtemplatesbyid ({ commit }, object) {
            commit('GET_BILLINGS_TEMPLATES_BY_ID', await BillingAuthorizationRepository.getbillingauthorizationtemplatesbyid(object));
        },
        async addbillingauthorizationtemplates ({ commit }, object) {
            return BillingAuthorizationRepository.addbillingauthorizationtemplates(object);
        },
        async editbillingauthorizationtemplates ({ commit }, object) {
            return BillingAuthorizationRepository.editbillingauthorizationtemplates(object);
        },
        async deletebillingauthorizationtemplates ({ commit }, object) {
            return BillingAuthorizationRepository.deletebillingauthorizationtemplates(object);
        },

    },
    getters: {},
};
