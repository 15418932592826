import Repository from '../../repositories/RepositoryFactory';
const ReportsRepository = Repository.get('reportsRepo');

export default {
	namespaced: true,
	state: {
		reports: [],
		allReports: [],
		allReportsForStaff: [],
	},
	mutations: {
		REPORTS_FOR_PATIENTS (state, payload) {
			state.reports = payload;
		},
		ALL_REPORTS_FOR_PATIENTS (state, payload) {
			state.allReports = payload;
		},
		ALL_REPORTS_FOR_STAFF (state, payload) {
			state.allReportsForStaff = payload;
		},
	},
	actions: {
		async getReportsRelatedPatients ({ commit }, data) {
			commit('REPORTS_FOR_PATIENTS', await ReportsRepository.getReportsRelatedPatients(data));
		},
		async getAllReportsRelatedPatients ({ commit }, data) {
			commit('ALL_REPORTS_FOR_PATIENTS', await ReportsRepository.getAllReportsRelatedPatients(data));
		},
		// getAllRelatedStaffAuditReport

		async getAllRelatedStaffAuditReport ({ commit }, data) {
			commit('ALL_REPORTS_FOR_STAFF', await ReportsRepository.getAllRelatedStaffAuditReport(data));
		},
		async getAuditReportDataLoginsAll ({ commit }, data) {
			return await ReportsRepository.getAuditReportDataLoginsAll(data);
		},
		async getauditreportdatatimetrackingsall ({ commit }, data) {
			return await ReportsRepository.getauditreportdatatimetrackingsall(data);
		},
		async getauditreportdatameasurementsall ({ commit }, data) {
			return await ReportsRepository.getauditreportdatameasurementsall(data);
		},
	},
};
