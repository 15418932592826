import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		detailedUserState: false,
		detailsDrawer: false, // used to open drawer for ADD
		updateDrawer: false, // used to open drawer for EDIT
		activePatientsDrawer: '',
		medicalNoteCategoryItems: null,
		viewDetailsDrawer: false, // used to open drawer for VIEW
		drawer: true,
		page: '',
		page2: '',
		filter: '',
		patientId: null,
		selectedDate: '',
		pageSize: { height: 500, width: 500 },
		dataForUser: [],
		medicalNoteData: null,
		item: null,
	},
	// getters: {
	// 	pageHeight: (state) => (add = 0) => { return add + state.pageSize.height + 'px'; },
	// }
	mutations: {
		SET_DETAILED_USER_STATE (state, payload) {
			state.detailedUserState = payload;
		},
		SET_DRAWER (state, payload) {
			state.drawer = payload;
		},
		SET_DETAIL_DRAWER_STATE (state, payload) {
			state.detailsDrawer = payload;
		},
		SET_DETAILS_DRAWER (state, payload) { // used to  open drawer for ADD
			if (payload.detailsDrawer) {
				state.detailsDrawer = payload.detailsDrawer;
				state.page = payload.page;
			} else {
				state.detailsDrawer = payload;
			}
		},
		SET_PATIENT_DETAILS_DRAWER (state, payload) {
			state.activePatientsDrawer = payload;
		},
		SET_UPDATE_DETAILS_DRAWER (state, payload) {
			if (payload.updateDrawer) {
				state.updateDrawer = payload.updateDrawer;
				state.page2 = payload.page2;
				state.medicalNoteData = payload;
				state.filter = payload.filter;
				state.selectedDate = payload.selectedDate;
				state.patientId = payload.patientId;
				state.item = payload.item;
			} else {
				state.updateDrawer = payload;
			}// used to open drawer for EDIT
			// state.updateDrawer = payload;
		},
		SET_EDIT_DETAILS_DRAWER (state, payload) { // used to open drawer for VIEW
			state.viewDetailsDrawer = payload;
			state.dataForUser = payload;
			state.item = payload;
		},
		SET_SCREEN_SIZE (state, payload) {
			state.pageSize = payload.height > 555 ? { height: payload.height - 350, width: payload.width } : { height: 200, width: payload.width };
		},
	},
	actions: {
		async setupWebSockets (store) {
			await store.dispatch('socket/setConnection');
		},
	},
	getters: {
		pageHeight: (state) => (add = 0) => { return add + state.pageSize.height + 'px'; },
		getPage (state) {
			return state.page;
		},
		getPage2 (state) {
			return state.page2;
		},
	},
	modules,
});
