import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async approveRequest (body) {
		const response = await _axios.post('appointmentrequests/approvereject', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
