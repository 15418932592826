// import { UsersError } from '@/lib/Errors';
// import _axios from '@/plugins/axios';
// export default {
// 	async getAppointments () {
// 		const response = await _axios.get('appointments');
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async getAppointmentsForToday (id) {
// 		const response = await _axios.get('appointments/appointmentsfordoctorfortoday', { headers: { doctorId: id } });
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async getAppointmentsForDoctor (id) {
// 		const response = await _axios.get('appointments/appointmentsfordoctor', { headers: { doctorId: id } });
// 		if (response.data.resFlag) {
// 			return response.data.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async getAppointmentsByHospitalId (id) {
// 		const response = await _axios.get('appointments/appointmentsforhospitalfortoday', { headers: { hospitalId: id } });
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async createAppointment (body) {
// 		const response = await _axios.post('appointments', body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async updateAppointments (body) {
// 		const response = await _axios.put(`appointments/${body.appointmentId}`, body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async newAppointment (body) {
// 		const response = await _axios.post('appointments/createappointment', body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async addAppointmentNote (body) {
// 		const response = await _axios.post('appointments/addnote', body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async getNotesWithPatient (patientId) {
// 		const response = await _axios.get('appointments/getnotesforpatientid', { headers: { patientId: patientId } });
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async startAppointment (body) {
// 		const response = await _axios.put('appointments/startvideocall', body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async stopAppointment (body) {
// 		const response = await _axios.put('appointments/stopvideocall', body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// 	async addAttendeeForAppointment (body) {
// 		const response = await _axios.post('appointments/addattendeeforappointment', body);
// 		if (response.data.resFlag) {
// 			return response.data;
// 		}
// 		throw new UsersError('No meta data!');
// 	},
// };
import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
	async getAppointments () {
		const response = await _axios.get('appointments');
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsForToday (id) {
		const response = await _axios.get('appointments/appointmentsfordoctorfortoday', { headers: { doctorId: id } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteNotes (body) {
		const response = await _axios.delete('appointments/deletenote', { headers: { noteId: body.noteId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsForDoctor (id) {
		const response = await _axios.get('appointments/appointmentsfordoctor', { headers: { doctorId: id } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsForHospitalForDate (body) {
		const response = await _axios.get('appointments/appointmentsforhospitalfordate', { headers: { hospitalId: body.hospitalId, paramDate: body.paramDate } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new UsersError('No meta data!');
	},
	async getAppointmentsByHospitalId (id) {
		const response = await _axios.get('appointments/appointmentsforhospitalfortoday', { headers: { hospitalId: id } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async createAppointment (body) {
		const response = await _axios.post('appointments', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async updateAppointments (body) {
		const response = await _axios.put(`appointments/${body.appointmentId}`, body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async newAppointment (body) {
		const response = await _axios.post('appointments/createappointment', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async addAppointmentNote (body) {
		const response = await _axios.post('appointments/addnote', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getNotesWithPatient (patientId) {
		const response = await _axios.get('appointments/getnotesforpatientid', { headers: { patientId: patientId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getmedicalnoteforpatientbyid (body) {
		const response = await _axios.get('medicalnote/getmedicalnoteforpatientbyid', { headers: { patientId: body.patientId, hospitalId: body.hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getmedicalnotesforhospital (body) {
		const response = await _axios.get('medicalnote/getmedicalnoteforhospital', { headers: { hospitalId: body.hospitalId, byMonth: body.byMonth, byYear: body.byYear } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getPatientPresenceForHospital (hospitalId) {
		const response = await _axios.get('patientpresence/getpatientpresenceforhospital', { headers: { hospitalId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getPatientPresenceByPatientId (hospitalId) {
		const response = await _axios.get('patientpresence/getpatientpresencebypatientid', { headers: { patientId: hospitalId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async getNotesById (noteId) {
		const response = await _axios.get('appointments/getnotebyid', { headers: { noteId: noteId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async editNotesForPatient (body) {
		const response = await _axios.put('appointments/editnote', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async addaAdditionalMedicalNoteDetail (body) {
		const response = await _axios.put(`medicalnote/addadditionalmedicalnotedetail/${body.medicalNoteId}`, body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deletePatientPresence (id) {
		try {
			const response = await _axios.delete(`patientpresence/${id}`);
			if (response.data.resFlag) {
				return response.data;
			} else {
				throw new Error('No meta data!');
			}
		} catch (error) {
			const response = error;
			return response;
		}
		},
	async addMedicalNote (body) {
		const response = await _axios.post('medicalnote', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async setPatientPresence (body) {
		const response = await _axios.post('patientpresence', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async editAditionalMedicalNote (object) {
		const { id, ...body } = object;
		const response = await _axios.put(`medicalnotedetail/${id}`, body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteAditionalNote (object) {
		const response = await _axios.delete(`medicalnotedetail/${object}`);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async deleteMedicalNote (object) {
		const response = await _axios.delete(`medicalnote/${object}`);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async startAppointment (body) {
		const response = await _axios.put('appointments/startvideocall', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async stopAppointment (body) {
		const response = await _axios.put('appointments/stopvideocall', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async addAttendeeForAppointment (body) {
		const response = await _axios.post('appointments/addattendeeforappointment', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
