import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
import Notification from '../../models/Notifications';
import Message from '../../models/Message';
export default {
	async sendMessage (body) {
		const response = await _axios.post('pushnotifications/sendnotificationtouser', body);
		if (response.data.resFlag) {
			const n = new Message(response.data.data);
			return n;
		}
		throw new UsersError('No meta data!');
	},
	async sendMessageToMedicalTeam (body) {
		const response = await _axios.post('pushnotifications/sendnotificationtomedicalteam', body);
		if (response.data.resFlag) {
			const n = new Message(response.data.data);
			return n;
		}
		throw new UsersError('No meta data!');
	},
	async markAsRead (notificationId) {
		const response = await _axios.get('pushnotifications/markasread', { headers: { notificationId: notificationId } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async markAllAsReadWithUser (id) {
		const response = await _axios.get('pushnotifications/markallnotificationsasreadwithuserfornotificationtype', { headers: { userId: id, notificationTypeId: 1 } });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
	async ALL_NOTIFICATIONS (filter) {
		const response = await _axios.get('pushnotifications/getmypushnotifications-minimal', { headers: { filterCase: filter } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async getNotificationsWithPatient (object) {
		const response = await _axios.get('pushnotifications/getmypushnotificationswithpatient', { headers: { filterCase: object.filterCase, patientId: object.patientId } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async getMessagesWithUser (object) {
		const response = await _axios.get('pushnotifications/getmymessageswithuser', { headers: { userId: object.id, filterCase: object.filterCase } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async processNotifications (body) {
		const response = await _axios.get('pushnotifications/markasprocessed', { headers: { notificationId: body.notificationId, resolutionComments: body.resolutionComments } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async getMessagesWithUserFromMedicalTeam (object) {
		const response = await _axios.get('pushnotifications/getmymessageswithuseronlygroupmessages', { headers: { userId: object.id, filterCase: object.filterCase } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async markNotificationsAsProcessed (body) {
		const response = await _axios.get('pushnotifications/markasprocessed', { headers: { notificationId: body.notificationId, resolutionComments: body.res } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async getNotificationDetailsById (object) {
		const response = await _axios.get('pushnotifications/getnotificationdetailsbyid', { headers: { notificationId: object.id } });
		if (response.data.resFlag) {
			const notifications = response.data?.data?.myNotifications.map(n => {
				const notification = new Notification(n);
				return notification;
			});
			return notifications;
			// return response.data?.data?.myNotifications
		}
		throw new UsersError('No meta data!');
	},
	async initVideoCall (body) {
		const response = await _axios.post('pushnotifications/initvideocallwithuser', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new UsersError('No meta data!');
	},
};
