module.exports = class PatientMetaData {
	constructor (object) {
		this.id = object.id;
		this.metadataInfo = object.metadataInfo;
		this.userId = object.userId;
		this.heightInCm = object.heightInCm;
		this.idealWeightCalculatedInKg = object.idealWeightCalculatedInKg;
		this.medicalRegistrationNumber = object.medicalRegistrationNumber;
		this.patientIdentifier = object.patientIdentifier;
		this.ssn = object.ssn;
		this.weightInKg = object.weightInKg;
		this.diagnose = object.diagnose;
		this.prescriptions = object.prescriptions;
	}
};
