import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			// light: {
			// 	primary: '#6600FF',
			// 	primary2: '#19003F',
			// 	chips: '#29085A',
			// 	secondary: '#E8E8E8',
			// 	dark_purple: '#19003F',
			// 	main_black: '#090017',
			// 	main_red: '#FF0000',
			// 	gray: '#E1DCE8',
			// 	white: '#fff',
			// 	// Old ones
			// 	alert_missed: '#E29500',
			// 	accent: colors.indigo.base, // #3F51B5
			// 	title_color: '#575CE5',
			// 	text_color: '#adb4c0',
			// 	button_color: '#4FC3F7',
			// 	card_color: '#575CE5',
			// 	kot_color: '#3b4e70',
			// 	btn_color: '#2e90bf',
			// 	drawer_color: '#f2f6fe',
			// 	black_inner: '#2e3455',
			// 	red_color: '#e45e67',
			// 	alert_red: '#F44336',
			//
			// 	chart_color_one: '#3949AB',
			// 	chart_color_two: '#B388FF',
			// 	chart_color_three: '#8E24AA',
			// },
			light: {
				primary: '#ff585f',
				primary2: '#a51717',
				chips: '#a51717',
				secondary: '#E8E8E8',
				dark_purple: '#a51717',
				main_black: '#090017',
				main_red: '#FF0000',
				gray: '#E1DCE8',
				white: '#fff',
				// Old ones
				alert_missed: '#E29500',
				accent: colors.indigo.base, // #3F51B5
				title_color: '#575CE5',
				text_color: '#adb4c0',
				button_color: '#4FC3F7',
				card_color: '#575CE5',
				kot_color: '#3b4e70',
				btn_color: '#2e90bf',
				drawer_color: '#f2f6fe',
				black_inner: '#2e3455',
				red_color: '#e45e67',
				alert_red: '#F44336',

				chart_color_one: '#3949AB',
				chart_color_two: '#B388FF',
				chart_color_three: '#8E24AA',
			},
		},
	},
});
