import { InvoicesError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
    // async getHospitals () {
    // 	const response = await _axios.get('hospital');
    // 	if (response.data.resFlag) {
    // 		return response.data;
    // 	}
    // 	throw new UsersError('No meta data!');
    // },
    // async getHospitalById (id) {
    // 	const response = await _axios.get(`/hospital/${id}`);
    // 	if (response.data.resFlag) {
    // 		const userJson = response.data.data;
    // 		return userJson;
    // 	} else {
    // 		throw new UsersError('Invalid id');
    // 	}
    // },
    // async createHospital (body) {
    // 	const response = await _axios.post('hospital', body);
    // 	if (response.data.resFlag) {
    // 		return response.data;
    // 	}
    // 	throw new UsersError('No meta data!');
    // },
    // async editHospital (body) {
    // 	const response = await _axios.put(`hospital/${body.id}`, body);
    // 	if (response.data.resFlag) {
    // 		const userJson = response.data;
    // 		return userJson;
    // 	} else {
    // 		throw new UsersError('Invalid id');
    // 	}
    // },
    // async deleteHospital (id) {
    // 	const response = await _axios.delete(`hospital/${id}`);
    // 	if (response.data.resFlag) {
    // 		const userJson = response.data.data;
    // 		return userJson;
    // 	} else {
    // 		throw new UsersError('Invalid id');
    // 	}
    // },
    async getInvoiceReferencesForOrganization (organizationId) {
        const headers = { headers: { organizationId: organizationId } };
        const response = await _axios.get('invoice/getinvoicereferencesfororganization', headers);
        if (response.data.resFlag) {
            return response.data;
        }
        throw new InvoicesError('No meta data!');
    },
    async getInvoices () {
        const response = await _axios.get('invoice/getinvoices');
        if (response.data.resFlag) {
            return response.data;
        }
        throw new InvoicesError('No meta data!');
    },
    async generateInvoiceForOrganization (body) {
        const response = await _axios.post('invoice/generateinvoicefororganization', body);
        if (response.data.resFlag) {
            return response.data;
        }
        throw new InvoicesError('No meta data!');
    },
    async updateInvoiceForOrganization (body) {
        const response = await _axios.put('invoice/updateinvoicefororganization', body);
        if (response.data.resFlag) {
            return response.data;
        }
        throw new InvoicesError('No meta data!');
    },
    async getInvoicePdf (object) {
        const response = await _axios({
            url: 'invoice/getinvoicepdf',
            method: 'GET',
            responseType: 'blob',
            headers: {
                invoiceId: object.invoiceId,
            },
        });
        if (response.status === 200) {
            return response;
        }
        throw new InvoicesError('No summary!');
    },
    async deleteInvoice (id) {
        const response = await _axios.delete(`invoice/${id}`);
        if (response.data.resFlag) {
            const userJson = response.data;
            return userJson;
        } else {
            throw new InvoicesError('Invalid id');
        }
    },

};
