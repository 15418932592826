import { UsersError } from '@/lib/Errors';
import TrustedHubs from '../../models/TrustedHubs';
// import axios from 'axios';
import _axios from '@/plugins/axios';
export default {
	async getHubsData (data) {
		// const config = {
		// 	// baseURL: process.env.VUE_APP_NO_SESSION,
		// 	baseURL: process.env.VUE_APP_API_URL,
		// 	timeout: 60 * 1000, // Timeout
		// 	withCredentials: false, // Check cross-site Access-Control
		// 	// Accept: '*/*',
		// };
		// const api = axios.create(config);
		// const response = await api.get('medicaldevicesdata/getmeasurementsforpatientbyidentifier', { params: { patientIdentifier: data.id, filterCase: data.filter } });
		const response = await _axios.get('medicaldevicesdata/getmeasurementsforpatientbyidentifier', { params: { patientIdentifier: data.id, filterCase: data.filter } });
		if (response.data.resFlag) {
			const object = response.data?.data[0] || [];
			const newObject = new TrustedHubs(object);
			return newObject;
		}
		throw new UsersError('No meta data!');
	},
	async getHubsDataCustomDate (data) {
		const response = await _axios.get('medicaldevicesdata/getmeasurementsforpatientbyidentifiercustomdate', { params: { patientIdentifier: data.id, startDate: data.startDate, endDate: data.endDate } });
		if (response.data.resFlag) {
			const object = response.data?.data[0] || [];
			const newObject = new TrustedHubs(object);
			return newObject;
		}
		throw new UsersError('No meta data!');
	},
};
