import Repository from '../../repositories/RepositoryFactory';

const TreatMentPlanRepository = Repository.get('treatmentplan');

export default {
	namespaced: true,
	state: {
		treamentPlans: [],
		treatmentPlan: [],
		relatedClientMonitoringPlans: [],
		selectedClientMonitoringPlan: null,
	},
	mutations: {
		TREATMENT_PLANS_FOR_USER (state, payload) {
			state.treamentPlans = payload;
		},
		CREATE_TREATMENT_PLAN_FOR_USER (state, payload) {
			state.treamentPlans.unshift(payload);
			if (state.treamentPlans.length > 1) {
				state.treamentPlans[1].status = 0;
			}
		},
		GET_CLIENT_MONITORING_PLANS (state, payload) {
			state.relatedClientMonitoringPlans = payload;
		},
		GET_CLIENT_MONITORING_PLAN_BY_ID (state, payload) {
			state.selectedClientMonitoringPlan = payload;
		},
		// DEASSIGN_DEVICE (state, payload) {
		// 	state.treamentPlans[0].treatmentPlanDevicesList = state.treamentPlans[0].treatmentPlanDevicesList.filter(d => { return d.id !== payload.id; });
		// },
		// ASSIGN_DEVICE (state, payload) {
		// 	state.treamentPlans[0].treatmentPlanDevicesList.unshift(payload);
		// },
		SET_TREATMENT_PLAN_FOR_PATIENT (state, payload) {
			state.treamentPlans = payload;
		},
	},
	actions: {
		async createTreatmentPlan ({ commit }, body) {
			const res = await TreatMentPlanRepository.createTreatmentPlan(body);
			commit('CREATE_TREATMENT_PLAN_FOR_USER', res);
			return res;
		},
		async getTreatmentPlansForPatient ({ commit }, id) {
			commit('TREATMENT_PLANS_FOR_USER', await TreatMentPlanRepository.getTreatmentPlansForPatient(id));
		},
		async getActiveTreatmentPlan ({ commit }, id) {
			const res = await TreatMentPlanRepository.getActiveTreatmentPlan(id);
			return res;
		},
		async deassignDevice ({ commit }, id) {
			return await TreatMentPlanRepository.deassignDevice(id);
		},
		async assignDevice ({ commit }, body) {
			return await TreatMentPlanRepository.assignDevice(body);
		},
		async updateDeviceTimes ({ commit }, body) {
			const res = await TreatMentPlanRepository.updateDeviceTimes(body);
			return res;
		},
		async updateTreatmentPlan ({ commit }, body) {
			const res = await TreatMentPlanRepository.updateTreatmentPlan(body);
			return res;
		},
		async updateTreatmentPlanDevice ({ commit }, body) {
			const res = await TreatMentPlanRepository.updateTreatmentPlanDevice(body);
			return res;
		},
		async updateTitleDescription ({ commit }, body) {
			const res = await TreatMentPlanRepository.updateTitleDescription(body);
			return res;
		},
		async duplicateActiveMonitoringPlan ({ commit }, id) {
			const res = await TreatMentPlanRepository.duplicateActiveMonitoringPlan(id);
			return res;
		},

		// the functions below are related to client monitoring plans
		async relatedClientMonitoringPlans ({ commit }) {
			commit('GET_CLIENT_MONITORING_PLANS', await TreatMentPlanRepository.relatedClientMonitoringPlans());
		},
		async getClientMonitoringPlanById ({ commit }, id) {
			commit('GET_CLIENT_MONITORING_PLAN_BY_ID', await TreatMentPlanRepository.getClientMonitoringPlanById(id));
		},
		async createClientMonitoringPlan ({ commit }, body) {
			const res = await TreatMentPlanRepository.createClientMonitoringPlan(body);
			return res;
		},
		async updateClientMonitoringPlan ({ commit }, body) {
			const res = await TreatMentPlanRepository.updateClientMonitoringPlan(body);
			return res;
		},
		async deleteMonitoringPlan ({ commit }, body) {
			const res = await TreatMentPlanRepository.deleteMonitoringPlan(body);
			return res;
		},
		async deassignpatientfromclientmonitoringplan ({ commit }, body) {
			const res = await TreatMentPlanRepository.deassignpatientfromclientmonitoringplan(body);
			return res;
		},
		async assignpatienttodefaultclientmonitoringplan ({ commit }, body) {
			const res = await TreatMentPlanRepository.assignpatienttodefaultclientmonitoringplan(body);
			return res;
		},
	},
	getters: {},
};
