import _axios from '@/plugins/axios';
import { BillingsError, InvoicesError } from '../../../lib/Errors';

export default {
	async getbillingsummaryforallpatientsformonth (object) {
		// const response = await _axios.get('billing/getbillingsummaryforallpatientsformonth', { headers: object });
		// const response = await _axios.get('billing/getbillingsummaryforallpatientsformonthv2', { headers: object });
		const response = await _axios.get('billing/getbillingsummaryforallpatientsformonthv3', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data?.billingHeaderSummaryData;
		}
		throw new BillingsError('No summary!');
	},
	async getbillingsummaryforpatientformonth (object) {
		const response = await _axios.get('billing/getbillingsummaryforpatientformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new BillingsError('No summary!');
	},
	async getbillingdetailsforpatientformonth (object) {
		const response = await _axios.get('billing/getbillingdetailsforpatientformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateforallhospitalpatientsformonth (object) {
		const response = await _axios.get('billing/generateforallhospitalpatientsformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateforpatientformonth (object) {
		const response = await _axios.get('billing/generateforpatientformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateforallorganizationpatientsformonth (object) {
		const response = await _axios.get('billing/generateforallorganizationpatientsformonth', { headers: object });
		if (response.data.resFlag) {
			return response.data?.data;
		}
		throw new BillingsError('No summary!');
	},
	async generateCsvOrganizationPatientsForMonth (object) {
		const response = await _axios({
			// url: 'billing/generatecsvorganizationpatientsformonth',
			url: 'billing/generatecsvorganizationpatientsformonthV2', // new endpoint with improved response
			method: 'GET',
			responseType: 'blob',
			headers: {
				organizationId: object.organizationId,
				month: object.month,
				year: object.year,
			},
		});
		if (response.status === 200) {
			return response;
		}
		throw new BillingsError('No summary!');
	},
	async generateCsvHospitalPatientsForMonth (object) {
		const response = await _axios({
			url: 'billing/generatecsvhospitalpatientsformonth',
			method: 'GET',
			responseType: 'blob',
			headers: {
				hospitalId: object.hospitalId,
				month: object.month,
				year: object.year,
			},
		});
		if (response.status === 200) {
			return response;
		}
		throw new BillingsError('No summary!');
	},
	async getBillingLinksForOrganization () {
		const response = await _axios.get('billingcodelink');
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new BillingsError('No summary!');
	},
	async getCsvDataForMonth (object) {
		const response = await _axios({
			url: 'billing/getcsvdataformonth',
			method: 'GET',
			headers: {
				organizationId: object.organizationId,
				month: object.month,
				year: object.year,
			},
		});

		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new Error('No meta data!');
		}
	},
	async createBillingLinksForOrganization (body) {
		const response = await _axios.post('billingcodelink', body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new BillingsError('No summary!');
	},
	async deleteBillingLink (id) {
		const response = await _axios.delete(`billingcodelink/${id}`);
		if (response.data.resFlag) {
			return response.data;
		} else {
			throw new BillingsError('No summary!');
		}
	},
	async editBillingLink (body, id) {
		const response = await _axios.put(`billingcodelink/${id}`, body);
		if (response.data.resFlag) {
			return response.data;
		}
		throw new InvoicesError('No meta data!');
	},
	async sendBillingLink (billingCodeLinkId) {
			const response = await _axios.post(
				'billingcodelink/sendemail',
				{}, // Empty body
				{
					headers: {
						billingCodeLinkId: billingCodeLinkId,
					},
				},
			);

			if (response.data.resFlag) {
				return response.data;
			} else {
				throw new Error('No meta data!');
			}
	},
};
