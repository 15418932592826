import GoogleAPIRepository from '../../repositories/v1/GoogleAPIRepository';
export default {
	namespaced: true,
	state: {
		geolocation: null,
	},
	mutations: {
		GEO_LOCATION (state, payload) {
			state.geolocation = payload;
		},
	},
	actions: {
		// eslint-disable-next-line no-unused-vars
		// async getGeoLocation ({ commit }, city) {
		// 	const resgeoLocation = await GoogleAPIRepository.getLocationDetails(city);
		// 	if (resgeoLocation) {
		// 		return resgeoLocation.results[0];
		// 	}
		// 	return resgeoLocation;
		// },
		async getGeoLocation ({ commit }, address) {
			const resgeoLocation = await GoogleAPIRepository.getLocationDetails(address);
			if (resgeoLocation && resgeoLocation.length > 0) {
				return resgeoLocation[0];
			}
			return resgeoLocation;
		},
		// eslint-disable-next-line no-unused-vars
		async getTimeZone ({ commit }, object) {
			const res = await GoogleAPIRepository.getTimeZone(object);
			return res;
		},
	},
	getters: {},
};
