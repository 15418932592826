import Repository from '../../repositories/RepositoryFactory';
import MedicalNoteCategoryRepository from '@/data/repositories/v1/MedicalNoteCategoryRepository';
Repository.get('medicalnotecategory');
export default {
    namespaced: true,
    state: {
        // billingsTemplates: [],
        // getBillingsTemplateById: null,
        // billingTemplatesAll: [],
        medicalNotesCategories: [],
        medicalNotesCategoriesForHospital: [],
        aditionalNoteId: null,
    },
    mutations: {
        // GET_BILLINGS_TEMPLATES (state, payload) {
        //     state.billingsTemplates = payload;
        // },
        // GET_BILLINGS_TEMPLATES_BY_ID (state, payload) {
        //     state.getBillingsTemplateById = payload;
        // },
        // GET_BILLINGS_TEMPLATES_ALL (state, payload) {
        //     state.billingTemplatesAll = payload;
        // },
        GET_MEDICAL_NOTES_CATEGORY (state, payload) {
            state.medicalNotesCategories = payload;
        },
        GET_MEDICAL_NOTES_CATEGORY_FOR_HOSPITAL (state, payload) {
            state.medicalNotesCategoriesForHospital = payload;
        },
        SET_ADITIONAL_NOTE_ID (state, payload) {
            state.aditionalNoteId = payload;
        },
    },
    actions: {
        async medicalnotecategorytemplate ({ commit }, object) {
            commit('GET_MEDICAL_NOTES_CATEGORY', await MedicalNoteCategoryRepository.medicalnotecategorytemplate(object));
        },
        async medicalnotecategorytemplateforhospital ({ commit }, object) {
            commit('GET_MEDICAL_NOTES_CATEGORY_FOR_HOSPITAL', await MedicalNoteCategoryRepository.medicalnotecategorytemplateforhospital(object));
        },
        // async getbillingauthorizationtemplatesbyid ({ commit }, object) {
        //     commit('GET_BILLINGS_TEMPLATES_BY_ID', await MedicalNoteCategoryRepository.getbillingauthorizationtemplatesbyid(object));
        // },
        async addmedicalnotecategorytemplate ({ commit }, object) {
            return MedicalNoteCategoryRepository.addmedicalnotecategorytemplate(object);
        },
        async editmedicalnotecategorytemplate ({ commit }, object) {
            return MedicalNoteCategoryRepository.editmedicalnotecategorytemplate(object);
        },
        // async billinguserauthorizationV1 ({ commit }, object) {
        //     commit('GET_BILLINGS_TEMPLATES_ALL', await MedicalNoteCategoryRepository.billinguserauthorizationV1(object));
        // },
        async deletemedicalnotecategorytemplate ({ commit }, object) {
            return MedicalNoteCategoryRepository.deletemedicalnotecategorytemplate(object);
        },
        async medicalnotecategorytemplatechangestatus ({ commit }, object) {
            return MedicalNoteCategoryRepository.medicalnotecategorytemplatechangestatus(object);
        },

    },
    getters: {},
};
