import { UsersError } from '@/lib/Errors';
import _axios from '@/plugins/axios';
export default {
    async getMedicalInstitutions (body) {
        const response = await _axios.get('admin/getpatientslistformedicalinstitutionwithbillings', { headers: { filterCase: body.filterCase, medicalInstitutionId: body.medicalInstitutionId } });
        if (response.status === 200) {
            if (response.data.resFlag) {
                return response.data.data;
            } else return [];
        }
        throw new UsersError('No meta data!');
    },
    async getBillingReport (body) {
        const response = await _axios.get('admin/getbillingreport', { headers: { filterCase: body.filterCase } });
        if (response.status === 200) {
            if (response.data.resFlag) {
                return response.data.data;
            } else return [];
        }
        throw new UsersError('No meta data!');
    },
};
