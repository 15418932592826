import _axios from '@/plugins/axios';
import { BillingsError } from '../../../lib/Errors';

export default {
	async getLogs (headers) {
		const response = await _axios.get('systemlog/getsystemlogs', { headers: headers });
		if (response.data.resFlag) {
			return response.data;
		}
		throw new BillingsError('Error geting time tracking info');
	},
	async getLogById (id) {
		const response = await _axios.get('systemlog/getsystemlogbyid', {
			headers: { systemlogId: id }, // Ensure the ID is set correctly in headers
		});
		if (response.data.resFlag) {
			return response.data;
		}
		throw new BillingsError('Error getting time tracking info');
	},
};
