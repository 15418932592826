/* eslint-disable no-unused-vars */
import Repository from '../../repositories/RepositoryFactory';

const DoctorMetaDataRepository = Repository.get('doctormetadata');

export default {
	namespaced: true,
	state: {
		metaData: null,
		editedData: null,
	},
	mutations: {
		GET_META_DATA (state, payload) {
			state.metaData = payload;
		},
		EDIT_META_DATA (state, payload) {
			state.editedData = payload;
		},
	},
	actions: {
		async getMetaData ({ commit }, id) {
			commit('GET_META_DATA', await DoctorMetaDataRepository.getMetaData(id));
		},
		async editDoctorMetaData ({ commit }, body) {
			const res = await DoctorMetaDataRepository.editDoctorMetaData(body);
			return res;
		},
		async assignDoctorToSpetialization ({ commit }, body) {
			const res = await DoctorMetaDataRepository.assignDoctorToSpetialization(body);
			return res;
		},
		async deAssignDoctorToSpetialization ({ commit }, body) {
			const res = await DoctorMetaDataRepository.deAssignDoctorToSpetialization(body);
			return res;
		},
	},
	getters: {},
};
