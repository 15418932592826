/* eslint-disable camelcase */
import Vue from 'vue';
import {
	extend,
	ValidationObserver,
	ValidationProvider,
} from 'vee-validate';
import {
	email,
	max,
	min,
	numeric,
	required,
	alpha_num,
	regex,
} from 'vee-validate/dist/rules';

import { AsYouType, isValidPhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

extend('required', {
	...required,
	message: 'This field is required',
});

extend('regex', {
	...regex,
	message: 'This field needs to be formatted',
});

extend('max', {
	...max,
	message: 'This field must be {length} characters or less',
});

extend('min', {
	...min,
	message: 'This field must be {length} characters or more',
});

extend('email', {
	...email,
	message: 'This field must be a valid email',
});

extend('numeric', numeric);

extend('alphanumeric', {
	...alpha_num,
	message: 'This field must contain alphabetic characters or numbers',
});

extend('phone', {
	validate: (value, args) => {
		const phoneNumber = parsePhoneNumberFromString(value);

		if (!phoneNumber) {
			return false;
		}

		const asYouType = new AsYouType(phoneNumber.country);
		const formattedPhoneNumber = asYouType.input(value);

		if (phoneNumber.isValid() && args.localSelectedMedicalInstitution) {
			args.localSelectedMedicalInstitution.contactPhone = formattedPhoneNumber;
		}

		return isValidPhoneNumber(value);
	},
	message: 'This field must be a valid phone number',
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
