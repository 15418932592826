import { SaRepositoryError } from '@/lib/Errors';
import _axios from '@/plugins/axios';

export default {
	async getPatientsForMedicalInstitution (object) {
		const response = await _axios.get('admin/getpatientslistformedicalinstitution', { headers: { filterCase: object.filterCase, medicalInstitutionId: object.medicalInstitutionId } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new SaRepositoryError('No meta data!');
	},
	async getPatientDetails (object) {
		const response = await _axios.get('admin/getpatientdetails', { headers: { filterCase: object.filterCase, patientId: object.patientId, year: object.year, month: object.month } });
		if (response.data.resFlag) {
			return response.data.data;
		}
		throw new SaRepositoryError('No meta data!');
	},
};
