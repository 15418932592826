import Repository from '../../repositories/RepositoryFactory';

const AppointmentsRepository = Repository.get('appointments');

export default {
	namespaced: true,
	state: {
		appointments: null,
		appointmentsForToday: null,
		appointmentsForDoctor: [],
		notes: [],
		notesForDownload: [],
		medicalNotesForHospital: [],
		patientsPresenceForHospital: [],
		patientsPresenceByPatientId: [],
		noteById: null,
		selectedNote: [],
		refreshNotes: null,
	},
	mutations: {
		GET_APPOINTMENTS (state, payload) {
			state.appointments = payload.data;
		},
		CREATE_NOTE (state, payload) {
			state.refreshNotes = payload;
		},
		GET_APPOINTMENTS_FOR_TODAY (state, payload) {
			state.appointmentsForToday = payload.data;
		},
		GET_APPOINTMENTS_FOR_DOCTOR (state, payload) {
			state.appointmentsForToday = payload.data;
		},
		CREATE_APPOINTMENTS (state, payload) {
			var today = new Date().toISOString();
			payload.data.appointmentDateTime = payload.data.appointmentDateTime.split('Z')[0];
			if (payload.data.appointmentDateTime.split('T')[0] === today.split('T')[0]) {
				state.appointmentsForToday.unshift(payload.data);
			}
		},
		ADD_NOTE () {
		},
		GET_NOTES (state, payload) {
			state.notes = payload?.data;
			state.selectedNote = payload?.data;
		},
		GET_NOTE_BY_ID (state, payload) {
			state.noteById = payload?.data;
		},
		GET_NOTES_BY_PATIENT_ID (state, payload) {
			state.notes = payload.data;
			state.notesForDownload = payload.data;
		},
		GET_NOTES_FOR_HOSPITAL (state, payload) {
			state.medicalNotesForHospital = payload.data;
		},
		GET_PATIENT_PRESENCE_FOR_HOSPITAL (state, payload) {
			state.patientsPresenceForHospital = payload.data;
		},
		GET_PATIENT_PRESENCE_BY_PATIENT_ID (state, payload) {
			state.patientsPresenceByPatientId = payload.data;
		},
	},
	actions: {
		async getAppointments ({ commit }) {
			commit('GET_APPOINTMENTS', await AppointmentsRepository.getAppointments());
		},
		async getAppointmentsForToday ({ commit }, id) {
			commit('GET_APPOINTMENTS_FOR_TODAY', await AppointmentsRepository.getAppointmentsForToday(id));
		},
		async getAppointmentsForDoctor ({ commit }, id) {
			const response = await AppointmentsRepository.getAppointmentsForDoctor(id);
			return response;
		},
		async getAppointmentsByHospitalId ({ commit }, id) {
			commit('GET_APPOINTMENTS_FOR_TODAY', await AppointmentsRepository.getAppointmentsByHospitalId(id));
		},
		// eslint-disable-next-line no-unused-vars
		async createAppointment ({ commit }, body) {
			commit('CREATE_APPOINTMENTS', await AppointmentsRepository.createAppointment(body));
		},
		async getAppointmentsForHospitalForDate ({ commit }, body) {
			const response = await AppointmentsRepository.getAppointmentsForHospitalForDate(body);
			return response;
		},
		async newAppointment ({ commit }, body) {
			commit('CREATE_APPOINTMENTS', await AppointmentsRepository.newAppointment(body));
		},
		async addAppointmentNote ({ commit }, body) {
			commit('ADD_NOTE', await AppointmentsRepository.addAppointmentNote(body));
		},
		async getNotesWithPatient ({ commit }, patientId) {
			commit('GET_NOTES', await AppointmentsRepository.getNotesWithPatient(patientId));
		},
		async getmedicalnoteforpatientbyid ({ commit }, body) {
			commit('GET_NOTES_BY_PATIENT_ID', await AppointmentsRepository.getmedicalnoteforpatientbyid(body));
		},
		async getmedicalnotesforhospital ({ commit }, body) {
			commit('GET_NOTES_FOR_HOSPITAL', await AppointmentsRepository.getmedicalnotesforhospital(body));
		},
		async getPatientPresenceForHospital ({ commit }, body) {
			commit('GET_PATIENT_PRESENCE_FOR_HOSPITAL', await AppointmentsRepository.getPatientPresenceForHospital(body));
		},
		async getPatientPresenceByPatientId ({ commit }, body) {
			commit('GET_PATIENT_PRESENCE_BY_PATIENT_ID', await AppointmentsRepository.getPatientPresenceByPatientId(body));
		},
		async addaAdditionalMedicalNoteDetail ({ commit }, object) {
			return AppointmentsRepository.addaAdditionalMedicalNoteDetail(object);
		},
		async deletePatientPresence ({ commit }, object) {
			return AppointmentsRepository.deletePatientPresence(object);
		},
		async setPatientPresence ({ commit }, object) {
			return AppointmentsRepository.setPatientPresence(object);
		},
		async addMedicalNote ({ commit }, object) {
			return AppointmentsRepository.addMedicalNote(object);
		},
		async editAditionalMedicalNote ({ commit }, object) {
			return AppointmentsRepository.editAditionalMedicalNote(object);
		},
		async deleteAditionalNote ({ commit }, object) {
			return AppointmentsRepository.deleteAditionalNote(object);
		},
		async deleteMedicalNote ({ commit }, object) {
			return AppointmentsRepository.deleteMedicalNote(object);
		},
		async getNotesById ({ commit }, noteId) {
			commit('GET_NOTE_BY_ID', await AppointmentsRepository.getNotesById(noteId));
		},
		async editNotesForPatient ({ commit }, body) {
			const res = await AppointmentsRepository.editNotesForPatient(body);
			return res;
		},
		async startAppointment ({ commit }, body) {
			const res = await AppointmentsRepository.startAppointment(body);
			return res;
		},
		async deleteNote ({ commit }, body) {
			const res = await AppointmentsRepository.deleteNotes(body);
			return res;
		},
		async updateAppointments ({ commit }, body) {
			const res = await AppointmentsRepository.updateAppointments(body);
			return res;
		},
		async stopAppointment ({ commit }, body) {
			const res = await AppointmentsRepository.stopAppointment(body);
			return res;
		},
		async addAttendeeForAppointment ({ commit }, body) {
			const res = await AppointmentsRepository.addAttendeeForAppointment(body);
			return res;
		},
	},
	getters: {
		refreshNotes: (state) => state.refreshNotes,
		getNotes (state) {
			return state?.notes;
		},
		getOneNote (state) {
			return state.selectedNote;
		},
		// getNotesv2: (state) => state?.notes,
		// filteredNotes: (state) => (searchTerm) => {
		// 	const searchLowerCase = searchTerm.toLowerCase();
		// 	return state.notes.filter((note) => {
		// 		const fullName = note.userData.firstName + ' ' + note.userData.lastName;
		// 		const fullNameLowerCase = fullName.toLowerCase();
		// 		const firstNameLowerCase = note.userData.firstName.toLowerCase();
		// 		const lastNameLowerCase = note.userData.lastName.toLowerCase();
		// 		return (
		// 			fullNameLowerCase.includes(searchLowerCase) ||
		// 			firstNameLowerCase.includes(searchLowerCase) ||
		// 			lastNameLowerCase.includes(searchLowerCase)
		// 		);
		// 	});
		// },
	},
};
